import React from 'react'
import { Link } from 'react-router-dom'
import classes from "./footer.module.css"
// import logo from "../../assets/images/foot-log.png"
import { FaFacebook, FaLinkedinIn, FaTiktok } from "react-icons/fa"
import { BsInstagram } from "react-icons/bs"

const Footer = () => {
    return (
        <footer>
            <section className={`container ${classes.foot}`}>
                {/* <div>
                    <Link to="/"> <img src={logo} alt="Logo" /></Link>
                    <div className={`${classes.footIcon} ${classes.mobil__foot__icon}`}>
                        <p>Bizi izləyin: </p>
                        <ul >
                            <Link to="https://www.facebook.com/avtopro.az" target='_blank'> <FaFacebook /></Link>
                            <Link to="https://www.instagram.com/avtopro_az/" target='_blank'> <BsInstagram /> </Link>
                            <Link to="https://www.linkedin.com/company/avtoproaz/" target='_blank'> <FaLinkedinIn /></Link>
                            <Link to=" http://www.tiktok.com/@avtopro.az" target='_blank'> <FaTiktok /></Link>

                        </ul>
                    </div>
                </div>
                <div className={classes.socialIcons}>
                    <p><Link to="tel:+994 99 753 00 13" ><span>Əlaqə nömrəsi:</span> +994 99 753 00 13</Link></p>
                    <p><Link to="mailto: info@avtopro.az" ><span>E-poçt:</span> info@avtopro.az</Link></p>
                    <div className={classes.footIcon}>
                        <p>Bizi izləyin:</p>
                        <ul >
                            <Link to="https://www.facebook.com/avtopro.az" target='_blank'> <FaFacebook /></Link>
                            <Link to="https://www.instagram.com/avtopro_az/" target='_blank'> <BsInstagram /> </Link>
                            <Link to="https://www.linkedin.com/company/avtoproaz/" target='_blank'> <FaLinkedinIn /></Link>
                            <Link to="http://www.tiktok.com/@avtopro.az" target='_blank'> <FaTiktok /></Link>
                        </ul>
                    </div>
                </div> */}
                <div>
                    <Link to="/"><h1 style={{fontSize: '38px', color: '#D4252A'}}>LOGO</h1></Link>
                    <div className={`${classes.footIcon} ${classes.mobil__foot__icon}`}>
                        <p>Bizi izləyin: </p>
                        <ul >
                            <Link to="" target='_blank'> <FaFacebook /></Link>
                            <Link to="" target='_blank'> <BsInstagram /> </Link>
                            <Link to="" target='_blank'> <FaLinkedinIn /></Link>
                            <Link to="" target='_blank'> <FaTiktok /></Link>

                        </ul>
                    </div>
                </div>
                <div className={classes.socialIcons}>
                    <p><Link to="tel:+994 00 000 00 00" ><span>Əlaqə nömrəsi:</span> +994 00 000 00 00</Link></p>
                    <p><Link to="mailto: info@email.az" ><span>E-poçt:</span> info@email.az</Link></p>
                    <div className={classes.footIcon}>
                        <p>Bizi izləyin:</p>
                        <ul >
                            <Link to="" target='_blank'> <FaFacebook /></Link>
                            <Link to="" target='_blank'> <BsInstagram /> </Link>
                            <Link to="" target='_blank'> <FaLinkedinIn /></Link>
                            <Link to="" target='_blank'> <FaTiktok /></Link>
                        </ul>
                    </div>
                </div>
            </section>
            <section className={classes.copyright}>
                <div className={`container ${classes.copyright__container}`}>
                    <p>Brand.az ©2023 brand.az,  All Rights Reserved.</p>
                    <ul >
                        <li>
                            <Link to ='/footer_privacy'>Məxfilik siyasəti</Link>
                        </li>
                        <li style={{textAlign:"right"}}>
                            <Link to ='/footer_terms'>Şərtlər və qaydalar</Link>
                        </li>
                        
                        <li >
                            <Link to='/footer_about' >Haqqımızda</Link>
                        </li>
                        <li style={{textAlign:"right"}}>
                            <Link > Tez-tez verilən suallar</Link>
                        </li>
                    </ul>
                </div>
            </section>
        </footer>
    )
}

export default Footer