import React from 'react'
import classes from "./mobil-filter.module.css"
import { FiSearch } from "react-icons/fi"
import Select from 'react-select';
import { useCarmake } from "../../../hooks/query/carMake";
import { useCarModels } from "../../../hooks/query/carModel";
import { useCarGenerations } from "../../../hooks/query/carGeneration";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { CustomStyles } from '../../../helpers/contants/s3';

const Mobilfilter = (props) => {

  const [showFilter, setShowFilter] = useState(true)
  const [showSearch, setShowSearch] = useState(false)

  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [carMakeId, setCarMakeId] = useState(Number(searchParams.get("car_make_id") ?? 0));
  const [carModelId, setCarModelId] = useState(Number(searchParams.get("car_model_id") ?? 0));
  const [carGenerationId, setCarGenerationId] = useState(Number(searchParams.get("car_generation_id") ?? 0));
  const [product_number, setProductNumber] = useState(searchParams.get("product_number") ?? "");

  const { data: carMakeData } = useCarmake({ id_car_type: 1, order: "name", direction: "asc" });
  const {
    data: carModelData,
    refetch: carModelRefetch,
    isStale: carModelIsStale,
  } = useCarModels({ id_car_make: carMakeId, order: "name", direction: "asc" });
  const {
    data: carGenerationData,
    refetch: carGenerationRefetch,
    isStale: carGenerationIsStale,
  } = useCarGenerations({ id_car_model: carModelId, order: "name", direction: "asc" });

  const handleCarMakeChange = (e) => {
    setCarMakeId(e.value);
    setCarModelId(0);
    setCarGenerationId(0);
  };

  const handleCarModelChange = (e) => {
    setCarModelId(e.value);
    setCarGenerationId(0);
  };

  const handleCargenerationChange = (e) => {
    setCarGenerationId(e.value);
  };

  useEffect(() => {
    if (carModelIsStale && carMakeId !== 0) carModelRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carMakeId]);

  useEffect(() => {
    if (carGenerationIsStale && carModelId !== 0) carGenerationRefetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carModelId]);



  const carMakeValues = carMakeData?.data?.map((item) => ({
    value: item.id,
    label: item.name,
  }));
  const carModelsValues = carModelData?.data?.map((item) => ({ value: item.id, label: item.name }));
  const carGenerationsValues = carGenerationData?.data?.map((item) => ({ value: item.id, label: item.name }));

  const handleSubmitCarInfo = (e) => {
    e.preventDefault();
    const queryParams = Object.fromEntries(searchParams.entries());

    queryParams.car_make_id = carMakeId;
    queryParams.car_model_id = carModelId;
    queryParams.car_generation_id = carGenerationId;
    delete queryParams.product_number;

    const updatedSearchParams = new URLSearchParams(queryParams);
    setSearchParams(updatedSearchParams);

    if (location.pathname === "/") {
      navigate(`/products?${updatedSearchParams.toString()}`);
    } else {
      navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
    }

  };

  const handleSubmitProducNumber = (e) => {
    e.preventDefault();
    const queryParams = Object.fromEntries(searchParams.entries());

    delete queryParams.car_make_id;
    delete queryParams.car_model_id;
    delete queryParams.car_generation_id;
    delete queryParams.shop_type;
    delete queryParams.shop_id;
    queryParams.product_number = product_number;

    const updatedSearchParams = new URLSearchParams(queryParams);
    setSearchParams(updatedSearchParams);

    if (location.pathname === "/") {
      navigate(`/product?${updatedSearchParams.toString()}`);
    } else {
      navigate(`/product?${updatedSearchParams.toString()}`);
    }
  };



  const handleFilter = () => {
    setShowFilter(true)
    setShowSearch(false)
  }

  const handleSearch = () => {
    setShowFilter(false)
    setShowSearch(true)

  }


  return (
    <section className={`${classes.mobilFilter} `}>
      <div className='container'>
        <div className="filterFlex">
          <button onClick={handleFilter} style={{ cursor: "pointer" }} className={showFilter ? "activeA" : ""}>Ümumi</button>
          <button onClick={handleSearch} style={{ cursor: "pointer" }} className={showSearch ? "activeA" : ""}>OEM koda görə</button>
        </div>
        {
          showFilter &&
          <form className={classes.commonFilter} onSubmit={handleSubmitCarInfo}>
            <Select
              className={classes.selectItem}
              onChange={handleCarMakeChange}
              placeholder={"Marka"}
              options={carMakeValues}
              value={carMakeValues?.find((option) => option.value === carMakeId)}
              styles={CustomStyles}
            />
            <Select
              className={classes.selectItem}
              onChange={handleCarModelChange}
              placeholder={"Model"}
              options={carModelsValues}
              value={carModelsValues?.find((option) => option.value === carModelId)}
              styles={CustomStyles}
            />
            <Select
              className={classes.selectItem}
              onChange={handleCargenerationChange}
              placeholder={"Istehsal tarixi"}
              options={carGenerationsValues}
              value={carGenerationsValues?.find((option) => option.value === carGenerationId)}
              styles={CustomStyles}
            />
            <button className={`${classes.btn__filter__search} btn btn__primary`}>Axtar</button>
          </form>
        }
        {
          showSearch &&
          <form className='oemCode' onSubmit={handleSubmitProducNumber}>
            <input
              type="text"
              placeholder="OEM koda görə axtar"
              value={product_number}
              onChange={(e) => setProductNumber(e.target.value)}
            />
            <button className={`${classes.btn__filter__search} btn btn__primary`}><FiSearch fontSize="2rem" /></button>
          </form>
        }

      </div>
    </section>
  )
}

export default Mobilfilter


