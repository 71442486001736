import React from 'react'
import { svgUnsucces } from '../../assets/icons/Icons'
import "./order.css"
import Meta from '../../components/ui/meta';


const PaymentUnsuccess = () => {

    const title = "Brand - Ödəniş uğursuz";

    return (
        <>
            <Meta title={title} />
            <section className={`container order__container`}>
                <div className='order__item'>
                    <p>{svgUnsucces}</p>
                    <h3>Ödəniş uğursuz</h3>
                </div>
            </section>
        </>
    )
}

export default PaymentUnsuccess