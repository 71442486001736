import React from 'react'
import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb'
import Menu from '../../components/ui/menu'
import classes from "./oils.module.css"
import img from "../../assets/images/oils.png"
import { Link } from 'react-router-dom'
import img1 from "../../assets/images/oil1.png"
import img2 from "../../assets/images/oil2.png"
import img3 from "../../assets/images/oil3.png"
import img4 from "../../assets/images/oil4.png"
import Product from '../../components/product/product'

const oils=[img1,img2,img3,img4]

const Oils = () => {
    
    const page = {
        pages: [
            {
                link: "/oils",
                content: "Yağlar və mayelər",
            }
        ],
    };

    const title= "Brand - Yaglar" ;
   

  return (
    <>
        <Menu title={title}/>
        <Breadcrumb page={page}/>
        <div className={classes.oilsBanner}>
            <img src={img} alt=""/>
        </div>
        <section className='container'>
            <h3 className={classes.oilTitle}>Yağlar və mayelər</h3>
            <div className={` ${classes.motorOils}`}>
            {
                oils.map((oil,i)=>(
                    <Link key={i}>
                    <img src={oil} alt=""/>
                </Link>
                ))
            }
            </div>
        </section>
        <Product end="end"/>
    </>
  )
}

export default Oils