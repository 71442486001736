import React, { memo, useState } from 'react'
import classes from "./menu.module.css"
import { Link } from 'react-router-dom'
import { useContext } from "react";
import { MenuContext } from "../../../context/MenuContext";
import p1 from "../../../assets/images/p1.png"
import o1 from "../../../assets/images/o1.png"
import m1 from "../../../assets/images/m1.png"
import s1 from "../../../assets/images/s1.png"
import t1 from "../../../assets/images/t1.png"
import u1 from "../../../assets/images/u1.png"
import d1 from "../../../assets/images/d1.png"
import { AiOutlineClose } from 'react-icons/ai'
import { useTranslation } from "react-i18next";
import { Youtube } from '../../../assets/icons/Icons';
import { Lang } from "../../../assets/icons/Icons"
import { CgProfile } from "react-icons/cg"
import { ModalOrder } from '../../../pages/home/service/modalorder';

const Menu = () => {

    const { t } = useTranslation();
    const { active, setActive } = useContext(MenuContext);
    const [isModalOpenOrder, setIsModalOpenOrder] = useState(false);

    const openModalOrder = () => {
        setIsModalOpenOrder(true);
        setActive(!active)
    };



    return (
        <>
            <div className={classes.menu__container}>
                <ul className={active ? `container ${classes.nav__active} ${classes.menu}` : `container ${classes.menu}`}>
                    <button id={classes.closeMenu} onClick={() => setActive(!active)}><AiOutlineClose /></button>
                    {/* <li className='mobil__menu__hidden'>
                    <Link to="/tezlikle" className='hoverColor ' onClick={() => setActive(!active)}><img src={p1} alt="" /> Fərdi elanlar</Link>
                </li> */}
                    <li>
                        <Link to="/shops" className='hoverColor ' onClick={() => setActive(!active)}><img src={m1} alt=""/> {t("shops")}</Link>
                    </li>
                    <li>
                        <Link to="/dealers" className='hoverColor ' onClick={() => setActive(!active)}><span><img src={d1} alt="" /></span> Rəsmi dilerlər</Link>
                    </li>
                    <li>
                        <Link to="/licenseplate" className='hoverColor' onClick={() => setActive(!active)}><img src={o1} alt="" />   Qeydiyyat nişanı</Link>
                    </li>
                    <li>
                        <Link to="/ustalar" className='hoverColor' onClick={() => setActive(!active)}><img src={u1} alt="" /> Ustalar</Link>
                    </li>
                    <li className='demo__menu__hidden'>
                        <Link to="/register" className='hoverColor ' onClick={() => setActive(!active)}><CgProfile fontSize="1.8rem" color="#D4252A" /> Profil</Link>
                    </li>
                    <li >
                        <Link to="/tezlikle" className='hoverColor' onClick={() => setActive(!active)}><img src={t1} alt="" /> Avto Salonlar</Link>
                    </li>
                    <li>
                        <Link to="" className='hoverColor' onClick={openModalOrder}><img src={o1} alt="" /> Xüsusi sifariş et</Link>
                    </li>
                    <li>
                        <Link to="/services" className='hoverColor' onClick={() => setActive(!active)}> <img src={s1} alt="" /> Servislər</Link>
                    </li>
                    <li className='mobil__menu__hidden demo__menu__hidden'>
                        <Link to="/" className='hoverColor ' onClick={() => setActive(!active)}><img src={p1} alt="" /> Xüsusi sifariş</Link>
                    </li>
                    <li className='mobil__menu__hidden demo__menu__hidden'>
                        <Link to="/" className='hoverColor ' onClick={() => setActive(!active)}><img src={p1} alt="" /> Sığorta kalkulyator</Link>
                    </li>
                    <li className='mobil__menu__hidden demo__menu__hidden'>
                        <Link to="/" onClick={() => setActive(!active)}><img src={p1} alt="" /> Online mağaza yarat</Link>
                    </li>
                    <li className={`demo__menu__hidden ${classes.mobil_flex}`}>
                        <Link to="/video" onClick={() => setActive(!active)}><p > {Youtube} Youtube HUB</p> </Link>
                    </li>
                    <li className={`demo__menu__hidden mobil_line_top ${classes.mobil_flex}`}>
                        <Link><p>{Lang} Language </p> </Link>
                    </li>
                </ul>
            </div>
           {isModalOpenOrder && <ModalOrder isModalOpenOrder={isModalOpenOrder} setIsModalOpenOrder={setIsModalOpenOrder} />}
        </>
    )
}

export default memo(Menu);


