import React from "react";
import { Link } from "react-router-dom";
import classes from "./boxes.module.css";
import { BiChevronRight } from "react-icons/bi";


const Boxes = (props) => {
    const { shopbox, name, image, onClickLink, onClick ,productCount} = props;

    return (
        <article className={`${classes.box} box_item`}>
            {shopbox && <p className={classes.box__count}> { productCount && <span>{productCount} </span>} məhsul </p>}
            <Link to={onClickLink} > <img src={image} alt="" /></Link>
            {onClickLink ? (
                <Link to={onClickLink} className={classes.box__link} onClick={onClick}>
                    {name} <BiChevronRight className={classes.rightIcon} />
                </Link>
            ) : (
                <p className={classes.box__link} onClick={onClick}>
                    {name} <BiChevronRight className={`${classes.rightIcon} rightIconMobile`} />
                </p>
            )}
        </article>
    );
};

export default Boxes;
