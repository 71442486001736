import React from 'react'
import Breadcrumb from "../../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../../components/ui/menu";
import Meta from "../../../components/ui/meta";
import styles from './footer_about.module.css'
import {footer_Helps} from '../../../assets/icons/Icons';
import { SlArrowLeft  } from "react-icons/sl";
import { Link } from "react-router-dom";


const FooterHelp = () => {
    const page = {
        pages: [
            {
                link: "/footer_help_center",
                content: "Tez-tez verilən suallar",
            },
        ],
    };

   const left_part=[
    {
        id:1,
        title:"Voluptate exercitationuis ullamco cillum dolor exercitationuis ullamco cillum dolor",
        description:"Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor. Voluptateullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit cupidatat irure."

    },
    {
        id:2,
        title:"Voluptate exercitationuis ullamco cillum dolor",
        description:"Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor. Voluptate exercitationuis ullamco cillum dolor. Voluptate exercitation incididunt aliquip deserunt reprehenderit elit laborum. N"

    },
    {
        id:3,
        title:"Voluptate exercitationuis ullamco cillum dolor",
        description:"Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor."

    },
    {
        id:4,
        title:"Voluptate exercitationuis ullamco cillum dolor",
        description:"Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor. Voluptate"

    },
    {
        id:5,
        title:"Voluptate exercitationuis ullamco cillum dolor",
        description:"Xercitation incididunt aliquip deserunt reprehenderit elit laborum. Nulla Lorem mollit  duis ullamco cillum dolor."

    },
   ]

    const title= 'Brand - Tez-tez verilən suallar'
  return (
    <>
    <Meta title={title} />
    <Menu />
      <Breadcrumb page={page} none='dnone' />
    <section className="container">
       <Link className={`${styles.lnone}`} to="/"><SlArrowLeft color='#181818' fontSize="2rem" /></Link>
        <h1 className= {`${styles.head} ${styles.greenhead}`} >Sizə necə kömək edə bilərik?</h1>
        
        <div className={`${styles.comman_wrapper2}`}>

            <div  >
            <h5 className={`${styles.often}`}>Tez-tez verilən suallar</h5>

              {
                left_part.map((item)=>{
                    return(
                        <div className={`${styles.right__part}`} key={item.id}>
                        <h3>
                            {`${item.id}. ${item.title}`}
                         </h3>
                        <p>{item.description}</p>
                        </div>
                        
                    )
                })
              }
            </div>

            <div className="right">
                <p>{footer_Helps}</p>
            </div>
        </div>
        
    </section>

    <form className={`${styles.contact_form}`}>
                <h3 className={`${styles.often}`}>Əlaqə</h3>
                <div className={`${styles.form_item}`}>
                    <input type="text" placeholder="Ad soyad"/>
                </div>
                <div className={`${styles.form_item}`}>
                    <input type="phone" placeholder="Telefon"/>
                </div>
                <div className={`${styles.form_item}`}>
                    <textarea rows='5' cols='50' placeholder='Mesaj'/>
                </div>
                <button type="submit"  className={` btn btn__primary ${styles.send_btn}`}>Göndər</button>
            </form>
  </>
  )
}

export default FooterHelp