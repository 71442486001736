import React, { memo, useEffect, useState } from "react";
import Tabpanel from "../Tabpanel/index";
import classes from "./category.module.css";
import MobilCategory from "../Tabpanel/select";
import Boxes from "../ui/boxes/boxes";
import { useChildCategories, useParentCategories } from "../../hooks/query/categories";

const Category = () => {
    const { data } = useParentCategories();
    const [categoryId, setCategoryId] = useState(54);
   
    const { data: childCategories, refetch, isStale } = useChildCategories(categoryId);

    useEffect(() => {
        if (categoryId && isStale) {
            refetch();
        }
    }, [categoryId, refetch, isStale]);


    const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/"

 
    return (
        <section className={`container ${classes.category}`}>
            <h3 className="text-title">Kateqoriyalar</h3>

            <Tabpanel setCategoryId={setCategoryId} data={data} />

            <MobilCategory setCategoryId={setCategoryId} data={data} />


            <div className={`${classes.category__container} ${classes.category_container_demo}`}>
                {!categoryId &&
                    data?.data[0].children?.map((category) => (
                        <Boxes
                            key={category.id}
                            name={category?.category_name}
                            image={img_url + category?.icon}                  
                            onClickLink={`/products?category_id=${category?.id}`}
                            shopbox={"shopbox"}
                            productCount={category?.products_count}
                        />
                    ))}
                {categoryId &&
                    childCategories?.data?.map((category) => (
                        <Boxes
                            key={category.id}
                            name={category?.category_name}
                            image={img_url + category?.icon}                 
                            onClickLink={`/products?category_id=${category?.id}`}
                            shopbox={"shopbox"}
                            productCount={category?.products_count}
                        />
                    ))}
            </div>

            <div className={`${classes.category__container} ${classes.category_mobil_container}`}>
                    {
                         data?.data?.map((category) => (
                            <Boxes
                                key={category.id}
                                name={category?.category_name}
                                image={img_url + category?.icon}                          
                                onClickLink={`/products?category_id=${category?.id}`}
                                shopbox={"shopbox"}
                                productCount={category?.products_count}
                            />
                        ))}
                    
            </div>
        </section>
    );
};

export default memo(Category);
