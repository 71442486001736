import { useQuery } from "@tanstack/react-query";
import { categoriesService, getCategory } from "../../services/categoryService";


export const useParentCategories = () => {
    const params = { parent_id: 0, order: "id", direction: "asc" ,};

    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["categories_parent"],
        queryFn: () => categoriesService(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, refetch, isStale };
};


export const useChildCategories = (parent_id, queyr_params = {}) => {
    const params = { parent_id: parent_id, ...queyr_params};

    const { data, isError, error, refetch } = useQuery({
        queryKey: [`categories`, params],
        queryFn: () => categoriesService(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });
  
    return { data, isError, error, refetch };
};


export const useChildCategoriesBySlug = (params) => {

    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: [`categories`, params],
        queryFn: () => categoriesService(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, refetch, isStale };
};


export const useCategory = (slug) => {
   
    const { data, isError, error, refetch, isLoading, isFetched, isStale } = useQuery({
        queryKey: [`category_${slug}`],
        queryFn: () => getCategory(slug),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
        enabled: false,
    });

    return { data, isError, error, refetch, isLoading, isFetched, isStale };
};
