import Skeleton from "./Skeleton"

const SkeletonPost = () => {
    return (
        <div className="post">
            <Skeleton classes="title  width-100" />
            <div style={{ display: "flex", justifyContent: "space-between", gap: "1rem" }}>
                <Skeleton classes="text width-50" />
                <Skeleton classes="text width-50" />
            </div>
            <Skeleton classes="text width-100" />
            <Skeleton classes="text width-100" />
            <Skeleton classes="text width-100" />
            <Skeleton classes="text width-100" />

        </div>
    )
}
export default SkeletonPost