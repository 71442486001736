import React from "react";
import classes from "./home.module.css";
import Menu from "../../components/ui/menu/index";
import Category from "../../components/category";
import e1 from "../../assets/images/e1.png";
import e2 from "../../assets/images/e2.png";
import e31 from "../../assets/images/e31.png";
import e4 from "../../assets/images/e4.png";
import Brand from "./brand/brand";
// import Video from "./video/video";
import Service from "./service/service";
import Partner from "./partner/partner";
import Filter from "../../components/ui/filter/filter";
import Mobilfilter from "../../components/ui/mobilfilter/mobilfilter";
import { Link } from "react-router-dom";
import Meta from "../../components/ui/meta";
// import BannerSlider from "../../components/bannerslider/index";
import SliderCard from "./slider/index"

const Home = () => {

    const title = 'Brand - Ehtiyat hissələri və aksesuarların satışı'


    return (
        <>
            <Meta title={title} />
            <Menu />    
            <header className={`${classes.header} headerban`}>
                {/* <BannerSlider mobilNone={"mobilNone"}/> */}
                <Filter home={"homePosition"} />
            </header>
            <Mobilfilter />
            <div style={{ background: "#F3F3F3", paddingBottom: "2rem", paddingTop: '8rem'}}>
                <SliderCard />
            </div>
            <Category />

            <section className={`container ${classes.everyCar}`}>
                <h3 className="text-title">Ən vacib ehtiyat hissələri</h3>
                <div className={classes.items}>
                    <Link to={`/products?category_id=156`} className={classes.item}>
                        <img src={e2} alt="" />
                        <h4>Əyləc bəndləri</h4>
                    </Link>

                    <Link to={`/products?category_id=70`}className={classes.item}>
                        <img src={e4} alt="" />
                        <h4>Yağlar və mayelər </h4>
                    </Link>
                    <Link to={`/products?category_id=45`} className={classes.item}>
                        <img src={e1} style={{ width: "4rem" }} alt="" />
                        <h4>Təkərlər</h4>
                    </Link>
                    <Link to={`/products?category_id=256`} className={classes.item}>
                        <img src={e31} alt="" />
                        <h4>Alışdırma şamları</h4>
                    </Link>
                </div>
            </section>

            {/* <BannerSlider /> */}
            <Brand />
            {/* <Video /> */}
            <Service />
            {/* <BannerSlider /> */}
            <Partner />
        </>
    );

};

export default Home;
