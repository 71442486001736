import React from 'react'
import { svgCancel } from '../../assets/icons/Icons'
import "./order.css"
import Meta from '../../components/ui/meta';

const PaymentCancel = () => {

  const title = "Brand - Ödəniş ləğv edildi";

  return (
    <>
      <Meta title={title} />
      <section className={`container order__container`}>
        <div className='order__item'>
          <p>{svgCancel}</p>
          <h3>Ödəniş ləğv edildi</h3>
        </div>
      </section>
    </>
  )
}

export default PaymentCancel