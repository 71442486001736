import React from 'react'
import classes from "./partner.module.css"
import p1 from "../../../assets/images/pp1.png"
import p2 from "../../../assets/images/pp2.png"
import p3 from "../../../assets/images/pp3.png"
import p4 from "../../../assets/images/pp4.png"
import p5 from "../../../assets/images/pp5.png"
import p6 from "../../../assets/images/pp6.png"
import p7 from "../../../assets/images/pp7.png"
import p8 from "../../../assets/images/pp8.png"
import p9 from "../../../assets/images/pp9.png"
import Slider from "react-slick";

const partners=[p1,p2,p3,p4,p5,p6,p7,p8,p9]

const Partner = () => {
  return (
    <section className={`container ${classes.partner__container}`}>
        <h3 className='text-title'>Partnyorlar</h3>
        {/* <div className={` ${classes.partner}`}>
            {
                partners.map((item,i)=>(
                    <div key={i} className={classes.partnerImg}>
                     <img src={item} alt=''/>
                    </div>
                ))
            }
        </div> */}
         <Slider {...settings}>
         {
                partners.map((item,i)=>(
                    <div key={i} className={classes.partnerImg}>
                     <img src={item} alt=''/>
                    </div>
                ))
            }
        </Slider>
    </section>
  )
}

export default Partner


var settings = {
  // dots: true,
  infinite: true,
  autoplay: true,
  speed: 1500,
  autoplaySpeed: 2000,
  slidesToShow: 6,
  slidesToScroll: 1,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
    
  ]
};