import React, { useEffect } from 'react'
import Card from '../../../components/ui/card/card'
import "./wishlist.css"
import { HiChevronLeft } from "react-icons/hi"
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import CartTabPanel from '../../../components/cartTab'
import classes from "../carts.module.css"
import Menu from '../../../components/ui/menu'


const WishList = () => {

  const { wishLists,visibleMobil,setVisibleMobil,getAllWishLists } = useContext(AuthContext)
   
  const handleLeftClick=()=>{
    setVisibleMobil(!visibleMobil)
  }

  useEffect(()=>{
    getAllWishLists()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <>
      <Menu />
      <section className={`${classes.carts__container} container `}>
        <CartTabPanel activeTab='wishlist' />
        <div className={visibleMobil ? 'container__tabs' : " activeTabs"}>
          <div className='mobil__title__cart'>
            <span className='left__mobil__icon' onClick={handleLeftClick}><HiChevronLeft fontSize="2rem" /></span>
            <h3> Seçilmiş məhsullar</h3>
            <span></span>
          </div>
          <div className='wishlist__title'>
            <h3> Seçilmiş məhsullar</h3>
            <p><strong>{wishLists.length}</strong> nəticə tapıldı</p>
          </div>
          <div className='wishlist__cards'>
            {
              wishLists.map((card) => (
                <Card key={card.id} card={card} />
              ))
            }
          </div>
        </div>
      </section>
    </>
  )
}

export default WishList