import React from 'react'
import { Link } from 'react-router-dom'
import classes from "./service-card.module.css"
import { AiFillCar } from "react-icons/ai"
import { Lock } from "../../assets/icons/Icons"
import { HiLocationMarker } from "react-icons/hi"

const ServiceCard = (props) => {

  const { data } = props
  const maxLength=18
  const name = `${data?.name} ${data?.surname} `

  const url_image = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/";

  const repairer_work = data?.main_position_categories.map((item) => (
    <p key={item.id}>{Lock} {item?.position_category?.name}</p>
  ))

  const repairer_car_make = data?.main_position_categories.map((item) => item?.car_makes.map((car) => (
    <p key={item.id}><AiFillCar/> {car?.car_make_id === 0 ? 'Bütün Markalar' : car?.car_make?.name}</p>
  )))

   const addressLength = data?.address.length > maxLength ? data?.address.slice(0, maxLength) + "..." : data?.address;

   const phoneNumber = data?.number
   const formattedNumber = String(phoneNumber).slice(1); 
  
  return (
    <article className={classes.service__card}>
      <Link to={`/ustalar/${data?.slug}`} className={classes.service__card__img}>
        <img src={url_image + data?.photo} alt="" />
      </Link>
      <div className={classes.service__card__body}>
        <h3 className='text-md'>{name}</h3>
        {repairer_work}
        {repairer_car_make}
        <p><Link target="_blank" to={`https://www.google.com/maps/search/${data?.address}`}><HiLocationMarker /> {addressLength}</Link></p>
        {/* <p><AiFillStar/> 4.8</p> */}
      </div>
      <Link to={`tel:994${formattedNumber}`} className={`btn btn__primary ${classes.service__card__btn}`}> Əlaqə saxla </Link>
    </article>
  )
}

export default ServiceCard