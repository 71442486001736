import request from "../helpers/api/request";

export const getCities = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/cities${queryString}`);

    return response.data;
};

export const getCityNumbers = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/region_numbers${queryString}`);

    return response.data;
};

