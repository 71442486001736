import React, { memo, useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./card.module.css";
import { FaHeart } from "react-icons/fa";
import { S3_LINK } from "../../../helpers/contants/s3";
import { EyeCardIcon, LocationCard } from "../../../assets/icons/Icons";
import slugify from 'react-slugify';
import { AuthContext } from "../../../context/AuthContext";
import { deleteWishlistsItem, postWishLists } from "../../../services/wishListService";


const Card = (props) => {
    const navigate = useNavigate()
    const { isAuthenticated, wishListItem, setWishListItem, getAllWishLists,setWishLists,wishLists } = useContext(AuthContext)
    const { card } = props;
    const maxLength = props.imgHeight ? 18 : 22
    const img_url = S3_LINK + card?.cover;

    const productName = card?.product_name?.length > maxLength ? card?.product_name.slice(0, maxLength) + "..." : card?.product_name;

    const isItemInWishList2 = wishListItem?.some((item) => item === card?.id)

    const [heart2, setHeart2] = useState(isItemInWishList2)


    const modifiedText = card?.product_name?.replace(/ə/g, 'e');
    const slug = slugify(modifiedText, { lower: true, strict: true, replacement: '-' });


    const handleAddToWishList2 = async (card) => {
        const data = {
            product_id: card?.id
        }

        const isItemInWishList = wishListItem?.some((item) => item === card?.id);

        if (!isItemInWishList) {
            setHeart2(!heart2)
            setWishListItem([...wishListItem, card?.id])
            setWishLists([...wishLists,card])
            if (!isAuthenticated) {
                navigate("/register")
            } else {
                try {
                    await postWishLists(data)
                } catch (error) {

                }finally {
                    getAllWishLists();
                  }
            }
        }

    }


    const handleRemoveFromWishList2 = async (card) => {

        const updatedWishItems = wishListItem.filter((wishItem) => wishItem !== card.id);

        if (updatedWishItems) {
            setHeart2(!heart2)
            setWishListItem(updatedWishItems)
            try {
                await deleteWishlistsItem(card?.id)
            } catch (error) {
                console.log(error)
            }finally {
                getAllWishLists();
              }
        }

    }



    return (
        <article className={`${classes.card} slider__card`} >
            <span>
                <FaHeart color={heart2 ? "#CF3434" : "#ccc"} onClick={() => (heart2 ? handleRemoveFromWishList2(card) : handleAddToWishList2(card))} />
            </span>
            <Link to={`/products/${slug}-${card.id}`} className={classes.card__image} target="_blank">
                <img src={img_url} style={{ height: `${props.imgHeight}rem` }} alt="" loading="lazy" />
            </Link>
            <div className={classes.card__body}>
                <div className={classes.d_flex}>
                    <p>
                        {!(card?.price === "0.00" || card?.price === null) && <strong>{card?.price} ₼</strong>}
                        {!(card?.old_price === "0.00" || card?.old_price === null) && (
                            <b className={classes.discount}>{card?.old_price} ₼ </b>
                        )}
                    </p>
                    {card?.shop_type === 2 && <button className="btn__card btn__card__primary">Mağaza</button>}
                    {card?.shop_type === 1 && <button className="btn__card btn__card__black">Diler</button>}
                    {card?.shop_id === 0 && <button className="btn__card btn__card__black">Fərdi</button>}
                </div>
                <p className={classes.card__text}>{productName}</p>

                <div className={classes.card__bottom}>
                    <p>{card?.city_name !== null && LocationCard} {card?.city_name ?? ""}</p>
                    <p>{EyeCardIcon} {card?.views ?? 0}</p>
                </div>

            </div>
        </article>
    );
};

export default memo(Card);
