import React, { useCallback, useEffect, useState } from 'react'
import classes from "./navbar.module.css"
import { useContext } from "react";
import { MenuContext } from "../../context/MenuContext";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
// import logo from "../../assets/images/logo.png"
// import { Car, Equipment, Moto  } from '../../assets/icons/Icons';
import { FaHeart, FaUser } from "react-icons/fa"
import { FaShoppingCart } from "react-icons/fa"
import { FiSearch } from "react-icons/fi"
import { TiLocation } from "react-icons/ti"
import { Bar } from '../../assets/icons/Icons';
import mobilLeft from "../../assets/images/top.png"
import MobilNavbar from './mobilnav/mobilnavbar';
import CountUp from 'react-countup';
// import { useTranslation } from "react-i18next";
import { ModalNav } from './modalNavbar/modalNav';
import { useAutoComplete, useProductCount } from '../../hooks/query/products';
import useDebounce from '../../hooks/useDebounce';
import { AuthContext } from '../../context/AuthContext';
import BannerSlider from '../../components/bannerslider';
import Card from '../../components/ui/card/card';


const Navbar = () => {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { active, setActive,showBasket } = useContext(MenuContext);

  const { isAuthenticated, setUser, user, setIsAuthenticated, wishLists, basketListsCount } = useContext(AuthContext)

  const { data } = useProductCount()

  const [showLang, setShowLang] = useState(false)
  const [visibleRegister, setVisibleRegister] = useState(false)
  const [modalSearch, setModalSearch] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [isModalOpenNav, setIsModalOpenNav] = useState(false);
  const [query, setQuery] = useState("");
  const debouncedSearchTerm = useDebounce(query, 200);
  const { data: autoCompleteData, isFetching: autoCompelteIsFetching, refetch } = useAutoComplete(debouncedSearchTerm);
  const [searchParams] = useSearchParams();
  const [queryItem, setQueryItem] = useState('')

  const handleLogout = () => {
    setIsAuthenticated(false)
    setUser(null)
    localStorage.removeItem('token');
    localStorage.removeItem('userData');
    setVisibleRegister(!visibleRegister)
  }

  // const [lang,setLang]=useState(localStorage.getItem('lang'))
  // const langs = ["en", "ru"]


  // const mobilHandle = () => {
  //   setModalSearch(true)
  //  
  // }

  useEffect(() => {
    if (debouncedSearchTerm) refetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  const handleChange = (e) => {
    setQueryItem(() => e.target.value)

    if (e.target.value.length > 0) {
      setOpenSearch(true)
      setModalSearch(true)
      setQuery(e.target.value)

    } else {
      setOpenSearch(false)
      setModalSearch(false)
    }
  }

  const handleOverlay = () => {
    setModalSearch(false)
    setOpenSearch(false)
  }

  const formatNumber = useCallback((value) => {
    const digits = value.toString().split("");
    const formattedDigits = digits.map((digit, index) => (
      `<span style="background:linear-gradient(180.77deg, #434242 24.2%, #8A8A8A 151.61%); ">${digit}</span>`
    ));

    return formattedDigits;
  }, []);


  //   const handleLang=(lang)=>{
  //    i18n.changeLanguage(lang);
  //    localStorage.setItem('lang', JSON.stringify(lang));
  //    setLang(lang)
  //    setShowLang(!showLang)

  // }
  // const filterLang=langs.filter((item)=>item!==lang)



  const openModal = () => {
    setIsModalOpenNav(true);
  };

  const handleSearch = (e) => {
    e.preventDefault()
    searchParams.set("search", debouncedSearchTerm);

    if (location.pathname === "/products") {
      navigate(`?${searchParams.toString()}`);
    } else {
      navigate(`/product?${searchParams.toString()}`);
    }

    setModalSearch(false)
    setOpenSearch(false)
  }

  const closeAutoComplete = (query) => {
    setModalSearch(false)
    setOpenSearch(false)
    setQueryItem(query)

  }


  useEffect(() => {
    const handleKeyUp = (event) => {
      if (event.key === "Enter") {
        setModalSearch(false)
        setOpenSearch(false)
      }
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);



  const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/"
  const img_small_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/"


  const overlaySearchClass = modalSearch
    ? `${classes.formSearch} ${classes.activeSearchForm} `
    : classes.formSearch;


  return (
    <>
      <div className={classes.mobilShowBanner}>
        {/* <BannerSlider /> */}
      </div>
      <nav>
        <div onClick={handleOverlay} className={modalSearch ? "overlay-search active" : "overlay-search"} ></div>
        <div onClick={() => setActive(!active)} className={active ? "overlay-search overlay-search-mobil  active" : "overlay-search"} ></div>

        {/* <div className={`${classes.nav__top}`}>
          <Link >{Car} {t("car")}</Link>
          <Link >{Equipment} Ağır texnika</Link>
          <Link >{Moto}  {t("moto")}  </Link>
        </div> */}

        <section className={`${classes.nav__bottom} container`}>
          <div className={`${classes.flex} ${classes.mobilFlex} `}>
            <Link className={classes.mobilBar} onClick={() => setActive(!active)}>{Bar}</Link>
            <Link to="/" className={classes.mobilLeft}><img src={mobilLeft} alt='' /> </Link>
          </div>
          <Link to="/" className={classes.logo}> <h1 style={{fontSize: '28px', color: '#D4252A'}}>LOGO</h1></Link>
          <div className={classes.countUp}>
            <CountUp
              start={0}
              end={data?.data?.count}
              separator=""
              duration={3}
              formattingFn={formatNumber}
            />
          </div>
          <div className={`${classes.flex}`}>
            <form onSubmit={handleSearch} className={`${overlaySearchClass} `}>

              <input type="text" placeholder='Search...' value={queryItem} onChange={handleChange} onClick={() => setModalSearch(true)} />
              {autoCompelteIsFetching ?
                <button><i className="fas fa-circle-notch fa-spin"></i></button> :
                <button type="submit" ><FiSearch color='#D4252A' fontSize="2rem" /></button>
              }

              <>
                {openSearch && <ul className={classes.openSearchMenu} style={{ height: autoCompleteData?.data?.products?.length >= 0 ? "50rem" : "0" }} >
                  {
                    autoCompleteData?.data?.products?.map((item, i) => (
                      <li key={i}>
                        <Link to={`/product?search=${item}`} onClick={() => closeAutoComplete(item)}>{item}</Link>
                      </li>
                    ))
                  }
                  <div className={classes.searchContainer} >
                    {
                      autoCompleteData?.data?.categories?.map((category, i) => (
                        <article key={category?.id} className={`${classes.boxSearch} box_item`} >
                          <Link to={`/products?category_id=${category?.id}`} onClick={() => closeAutoComplete()}> <img src={img_url + category?.icon} alt="" /></Link>
                          <p>{category?.category_name}</p>
                        </article>
                      ))
                    }
                  </div>
                  <div className={classes.searchContainer} >
                    {
                      autoCompleteData?.data?.products_items?.map((card) => (
                        <Card key={card.id} card={card} imgHeight={8} />
                      ))
                    }
                  </div>
                  <div className='more'><Link to={`/product?search=${query}`} onClick={() => closeAutoComplete()} >Hamısına bax</Link></div>
                </ul>}

              </>

            </form>
            <Link to="/cart/cartlist" className={`  ${classes.mobilShow}`}>
              <FaShoppingCart color={basketListsCount?.length > 0 ? "#D4252A" : '#434242'} fontSize="2rem" />
              <sup className={classes.sup}>{basketListsCount?.length > 0 ? basketListsCount.length : null}</sup>
            </Link>
          </div>
          <div className={`${classes.flex}  ${classes.mobilHidden}`}>
            <div className={classes.langs}>
              <Link className={classes.lang} onClick={() => setShowLang(!showLang)}> AZE <i className="fas fa-angle-down "></i></Link>
              {/* {
                    showLang && 
                    <div className={classes.dropdown_lang}>
                       {
                        langs.map((item,i)=>(
                          <Link key={i} onClick={()=>handleLang(item)}>{item}</Link>
                        ))
                       }
                  </div>
                  } */}
            </div>
            <Link to="/gomap"><TiLocation color='#DBBF5C' fontSize="2.2rem" /></Link>

          </div>
          <button className='btn btn__primary btn__nav' onClick={openModal}>Mağaza aç</button>
          <div className={`${classes.flex}  ${classes.mobilHidden}`}>
            <Link to="/cart/cartlist" className='basket_btn' ><FaShoppingCart color={basketListsCount?.length > 0 ? "#D4252A" : '#CDCDCD'} fontSize="1.8rem" />
              <sup className={classes.sup}>{basketListsCount?.length > 0 ? basketListsCount.length : null}</sup>

              {basketListsCount.length > 0 ? <div className={`${classes.small_basket} ${showBasket ?  "" : 'sm_basket'} `}>
                {/* <span>{successTick}<h5>Səbətə əlavə edildi!</h5></span> */}
                <div className={classes.product_detail}>
                  <div className={classes.product_img}>
                    <img src={img_small_url + basketListsCount[basketListsCount.length - 1]?.product?.cover} alt="" />
                  </div>
                  <div className={classes.product_info}>
                    <p>{basketListsCount[basketListsCount.length - 1]?.product?.product_name}</p>
                    <h3>{basketListsCount[basketListsCount.length - 1]?.product?.price} AZN</h3>
                  </div>
                </div>
                <div>
                  <button className="btn LightBtn">Səbətə get</button>
                </div>
              </div> : null}

            </Link>

            <div className={classes.visibleRegister}>
              <Link ><FaUser color='#CDCDCD' fontSize="1.7rem" onClick={() => setVisibleRegister(!visibleRegister)} /></Link>
              {
                visibleRegister &&
                <div className={classes.dropdown_lang}>
                  <h3 className={classes.registerLink}>
                    <Link to="/cart/cartlist" onClick={() => setVisibleRegister(!visibleRegister)}><FaUser color='#CDCDCD' fontSize="1.6rem" /></Link>
                    {isAuthenticated ? user.name : "Şəxsi kabinetim"}
                  </h3>
                  <div className={classes.btns__login}>
                    {isAuthenticated && <Link to="/" onClick={handleLogout} className='btn btn__primary'>Çıxış</Link>}
                    {!isAuthenticated && <Link to="/register" onClick={() => setVisibleRegister(!visibleRegister)} className='btn btn__primary'> Daxil ol </Link>}
                  </div>
                </div>
              }
            </div>

            <Link to="/cart/wishlist"> <FaHeart color='#CDCDCD' fontSize="1.8rem" /> <sup className={classes.sup}>{wishLists?.length > 0 ? wishLists.length : null}</sup></Link>

          </div>

        </section>


        <MobilNavbar />

        <ModalNav isModalOpenNav={isModalOpenNav} setIsModalOpenNav={setIsModalOpenNav} />

      </nav>
    </>
  )
}

export default Navbar