import { Helmet } from "react-helmet";
import React from "react";
import image from "../../assets/images/icontitle.png"

const Meta = (props) => {
 
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{props.title}</title>
      <link rel="icon" type="image/png" href={image} sizes="16x16" />
      <meta name='og:title' content={props.title}/>
      <meta property="og:url" content={window.location.href} />
      <meta name='keywords' content={props.keywords} />
      <meta name='description' content={props.description} />
      <meta name='og:image' content={props.image ? props.image : image }/>
    </Helmet>
  );
};

export default Meta;