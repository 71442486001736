import React, { useState } from "react";
import classes from "./shopProduct.module.css";
import Card from "../../components/ui/card/card";
import SkeletonPost from "../skletons/SkeletonPost";


const ShopProduct = (props) => {
    const { productsDiscount, productsIsFetching } = props;
   
    const [showAll, setShowAll] = useState(false);

    const handleClick = () => {
        setShowAll(!showAll);
    };

 
    return (
        <>
            <section className={`container ${classes.products}`}>
                <div className={classes.products__container}>
                    {productsIsFetching && [...Array(18).keys()].map(i => {
                        return <SkeletonPost key={i} />
                    })}
                </div>
            </section>
            {productsDiscount?.data?.data?.length > 0 ? (
                <section className={`container ${classes.products}`}>
                    <h3>{props.title}</h3>
                    <div className={classes.products__container}>
                        {productsDiscount?.data?.data?.slice(0, showAll ? productsDiscount?.data?.data?.length : 18).map((card) => <Card key={card.id} card={card} />)}
                    </div>

                    {
                        !props.link && productsDiscount?.data?.data?.length>18 && <div className="more">
                            <button type="button"  onClick={handleClick}>{showAll ? "Daha az gor " : "Hamısını gör"}</button>
                        </div>
                    }

                </section>
            ) : ""}
        </>
    );
};

export default ShopProduct;

