import React from 'react'
import soon from "../../assets/images/soon.png"

const Soon = () => {
  return (
    <section className='soon__container'>
      <div className='soon'>
        <img src={soon} alt=""/>
        {/* <h1 style={{lineHeight:"40px"}}>Texniki səbəblərə görə sayt müvəqqəti olaraq bağlıdır</h1> */}
        <h3>Tezliklə...</h3>
        {/* <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet. Exercitation veniam consequat sunt nostrud amet.</p> */}
    </div>
    </section>
  )
}

export default Soon