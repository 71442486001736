import React, { memo } from "react";
import { Link } from "react-router-dom";
import "../../../assets/styles/shop-card.css";

const Shopcard = ({ shop }) => {
    const image_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/"+shop.logo;
    return (
        <article className="shopCard">

            <Link to={`/shops/${shop.id}?shop_type=${2}&shop_id=${shop.id}`} className="shopCard__link">
                <img src={image_url} alt='' />
            </Link>
            <h3>{shop.name}</h3>
            <p className="shopCard__count">
                <span> {shop.products_count>0 ? `${shop.products_count} Məhsul` : "Məhsullar yüklənilir"} </span>
            </p>
        </article>
    );
};

export default memo(Shopcard);
