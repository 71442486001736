import request from "../helpers/api/request";


export const getOrderProducts = async (query_params = {}) => {
    const queryParams = convertToQueryParams(query_params);
    const response = await request.get(`/account/orders_products?${queryParams}`);

    return response.data;
};

export const createOrder = async (data) => {
    const response = await request.post('/account/orders', data);

    return response.data;
}

const convertToQueryParams = (params) => {
    const keys = Object.keys(params);
    const queryParams = keys.map(key => {
      if (Array.isArray(params[key])) {
        const values = params[key].map(value => `${key}[]=${encodeURIComponent(value)}`);
        return values.join('&');
      }
      return `${key}=${encodeURIComponent(params[key])}`;
    });
    return queryParams.join('&');
  };
  
  