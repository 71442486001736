import React, { useState, useEffect } from "react";
import { YMaps, Map, Placemark } from '@pbe/react-yandex-maps';
import classes from './goMap.module.css'
import { mapShop } from "../../services/shopService";


const GoMap = () => {
  const [shopMap, setShopMap] = useState([]);
  const [shopAddress,setShopAddress]=useState(null)

  useEffect(() => {
    const getMap = async () => {
      try {
        const response = await mapShop();
        setShopMap(response?.data || []);
        setShopAddress(response?.data[0])
      } catch (error) {
        console.log(error)
      }
    }

    getMap()
  }, [])

  const handleMapClick = (shop) => {
    setShopAddress(shop)
  }


const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/`
const shopImg=image_url + shopAddress?.cover

  return (
    <>
     <section className={classes.map}>
     <YMaps query={{ apikey: '879a3590-ccee-42bf-bd5d-4d336e4ad116' , lang: 'az' }}>
        <Map width={'100%'} height={'60vh'} defaultState={{ center: [40.41043107313508, 49.8225385944722], zoom: 12 }} options={{ suppressMapOpenBlock: true }} >
          {shopMap?.map((shop, index) => {
            if ( shop?.shop_information && shop?.shop_information[0]) {
              const latitude = parseFloat(shop?.shop_information[0]?.latitude);
              const longitude = parseFloat(shop?.shop_information[0]?.longitude);

              if (!isNaN(latitude) && !isNaN(longitude)) {
                return (
                  <Placemark
                    key={index}
                    geometry={[latitude, longitude]}
                    properties={{
                      balloonContent: shop?.name,
                      iconCaption: shop?.name,
                    }}
                    onClick={() => handleMapClick(shop)}
                  />
                );
              }
            }
            return null;
          })}
        </Map>
      </YMaps>
     </section>

      <section className={`container ${classes.map_main}`}>
        <div className={classes.goMapTop}>
          {/* <div className={classes.goMapTop__location}>
            <p className={classes.goMapTop__location_parargraf}>Mənə yaxın </p>
            <span className={classes.goMapTop__location_icon}>
              <i class="fa-sharp fa-solid fa-location-dot"></i>
            </span>
          </div> */}
          <div className={classes.goMapTop__buttons}>
            <button className={classes.goMapTop__buttons_btn}>Mağaza</button>
            {/* <button className={classes.goMapTop__buttons_btn}>Servis</button>
            <button className={classes.goMapTop__buttons_btn}>Usta</button> */}
          </div>
        </div>

        <div className={classes.adress}>
              <div className={classes.adress__image}>
                <img src={shopImg} alt="" />
              </div>
              <div className={classes.mercedes}>
                <div className={classes.adress__MercedesCenter}>
                  <h3 className={classes.adress__MercedesCenter_title}>
                    {shopAddress?.name}
                  </h3>
                  <ul className={classes.adress__MercedesCenter_info}>
                    {/* <li>
                      <i class="fa-solid fa-car"></i>5 km məsafə
                    </li> */}
                    <li>
                      <i class="fa-solid fa-phone"></i>  {shopAddress?.shop_information[0]?.phone1??""} /{shopAddress?.shop_information[0]?.phone2??""}
                    </li>
                    <li>
                      <i class="fa-sharp fa-solid fa-location-dot"></i>
                      {shopAddress?.shop_information[0]?.address}
                    </li>
                  </ul>
                </div>
    
                {/* <div className={classes.direction}>
                  <h3 className={classes.direction__title}>Necə gedə bilərəm?</h3>
                  <ul className={classes.direction__info}>
                    <li>
                      <i class="fa-solid fa-car"></i>10 dəq.
                    </li>
                    <li>
                      {train}
                      <span className={classes.direction__info_trainText}>
                        Koroglu
                      </span>
                      <span className={classes.direction__info_icon}>
                        <i class="fa-solid fa-arrow-right"></i>
                      </span>
                      <i class="fa-solid fa-person-walking"></i>m.s 10 dəq.
                    </li>
                    <li>
                      <i class="fa-solid fa-bus"></i>
                      N16{" "}
                      <span className={classes.direction__info_icon}>
                        {" "}
                        <i class="fa-solid fa-arrow-right"></i>
                      </span>
                      <i class="fa-solid fa-person-walking"></i>5 dəq.
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
      
      </section>
      
    </>
  );
};

export default GoMap;
