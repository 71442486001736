import React from 'react'
// import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb';
import Menu from "../../components/ui/menu/index"
// import ServiceCard from '../../components/serviceCard/index';
// import Select from 'react-select';
// import { FiChevronDown } from "react-icons/fi"
// import { FiChevronUp } from "react-icons/fi"
// import { Link } from 'react-router-dom';
import Soon from '../../components/soon';


const Service = () => {

  // const [visible, setVisible] = useState(true)
  // const [showFilter, setShowFilter] = useState(true)

  // const handleFilter = () => {
  //   setShowFilter(!showFilter)

  // }

  // const page = {
  //   pages: [
  //     {
  //       link: "/services",
  //       content: "services",
  //     }
  //   ],
  // };

  return (
    <>
      <Menu />
      <Soon />
      {/* <section className='container__bg'>
        <Menu />
        <Breadcrumb page={page} />
        <section className='service__container container' >
          <form className='service__left'>
            <h3 className='service__left__title'>Axtarış</h3>
            <div className='filterFlex'>
              <Link onClick={handleFilter} className={showFilter ? "activeA" : ""}>Ümumi axtarış</Link>
              <Link onClick={handleFilter} className={!showFilter ? "activeA" : ""}>Usta adına görə axtar</Link>
            </div>
            {
              showFilter && <div className='service__item__select'>
                <Select
                  className="service__select"
                  placeholder={'Ayaqaltılar '}
                  options={marka}
                />
                <Select
                  className="service__select"
                  placeholder={'Ayaqaltılar '}
                  options={marka}
                />
              </div>
            }
            {
              showFilter && <div className='service__category'>
                <h3 onClick={() => setVisible(!visible)}>Kateqoriya {visible ? <FiChevronUp /> : <FiChevronDown />}</h3>
                {
                  visible && <div className='service__category__filter'>
                    <label>
                      <input type="checkbox" />
                      ABS sisteminin təmiri
                    </label>
                    <label>
                      <input type="checkbox" />
                      Təkər təmiri
                    </label>
                    <label>
                      <input type="checkbox" />
                      Elektrik
                    </label>
                  </div>
                }

              </div>
            }
            {
              !showFilter && <input type="text" className='serviceFilter' placeholder='Servis adına görə axtar' />
            }
            <button className='btn btn__primary mobilW100'>Axtar</button>
          </form>
          <div className='service__right'>
            <div className='service__right__title'>
              <h3>Servis xidmətləri</h3>
              <p>22 nəticə tapıldı</p>
            </div>
            <div className='service__cards'>
              <ServiceCard />
              <ServiceCard />
              <ServiceCard />
              <ServiceCard />
              <ServiceCard />
            </div>
          </div>
        </section>

      </section> */}
    </>

  )
}

export default Service

