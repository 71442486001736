import { useQuery } from "@tanstack/react-query";
import { getCarModels } from "../../services/carModelService";

export const useCarModels = (params) => {
    const { data, isError, error, refetch, isStale } = useQuery({
        queryKey: ["car_models", params],
        queryFn: () => getCarModels(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
        enabled: false,
    });

    return { data, isError, error, refetch, isStale };
};
