import React from 'react'
import Slider from "react-slick";


const DetailSliderMobil = ({ data, size }) => {

    const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`

    return (
        <section  className={`detailSlider detailSliderMobil`}>
            <Slider {...settings} className="mainSlider ">
                {
                    data?.images?.map((item) => (
                        <div key={item.id}>
                            <img src={image_url + item?.directory + "/" + item?.file_name} alt="img" />
                        </div>
                    ))
                }
            </Slider>
        </section>
    )
}

export default DetailSliderMobil

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

