import classes from "./detail.module.css"
import DetailSlider from './detailslider';
import Detailtable from './detailtable';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb';
import Menu from '../../components/ui/menu/index';
import { useProduct } from '../../hooks/query/products';
import SimilarProduct from './similarProduct';
import Meta from '../../components/ui/meta'
// import img from "../../assets/images/kaptal.png"
import DetailSliderMobil from "./detailSliderMobil";
import { getBaskets, postProduct } from "../../services/basketService";
import { ToastContainer, toast } from 'react-toastify';
import './map.css';
import maplibregl from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { useEffect, useState, useRef, useContext } from "react";
// import { ModalNav } from "../../layouts/navbar/modalNavbar/modalNav";
import { ModalCall } from "./modalcall";
import { HomeIcon, LocationIcon } from "../../assets/icons/Icons";
import { AuthContext } from '../../context/AuthContext';
import { MenuContext } from "../../context/MenuContext";
// import { FaHeart } from "react-icons/fa";
// import {CgDanger} from "react-icons/cg";
// import {EyeCardIcon} from "../../assets/icons/Icons"


const Detail = () => {

  const navigate = useNavigate();
  const {isAuthenticated, basketListsCount, setBasketListsCount } = useContext(AuthContext)
  const { setShowBasket } = useContext(MenuContext)
  const { productId } = useParams()
  const urlArray = productId.split("-");
  const id = urlArray[urlArray.length - 1];
  const { data, isFetching } = useProduct(id);
  const [addToCart, setAddToCart] = useState(false)
  const [loading, setLoading] = useState(false)
  const [basket, setBasket] = useState(false)
  const [basketLists, setBasketLists] = useState([])
  const [isModalOpenCall, setIsModalOpenCall] = useState(false);
  const mapContainer = useRef(null);
  const mapLocation = useRef(null);
  const [lng] = useState(49.94901057127427);
  const [lat] = useState(40.41384243911918);
  const [zoom] = useState(9);
  const [API_KEY] = useState('j4WWgdwepZdF7D5WbiTh');

  const openModal = () => {
    setIsModalOpenCall(true);
  };

  const sendData = {
    product_id: data?.data?.id,
    count: 1
  }

  const [locations, setLocations] = useState([]);


  useEffect(() => {

    if (!isFetching) {
      const shopMap = data?.data?.shop?.shop_information?.map((item) => {
        return {
          lng: item?.longitude,
          lat: item?.latitude
        };
      });

      setLocations((p) => [...locations, shopMap].flat(1))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching]);


  useEffect(() => {
    if (mapLocation.current) return; //stops map from intializing more than once
    mapLocation.current = new maplibregl.Map({
      container: mapContainer.current,
      style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
      center: [lng, lat],
      zoom: zoom
    });
    // map.current.addControl(new maplibregl.NavigationControl(), 'top-right');

  });

  locations?.map((location) => {
    return new maplibregl.Marker({ color: "#FF0000" })
      .setLngLat([location.lng, location.lat])
      .addTo(mapLocation.current);
  });


  const sendDataSmall = {
    product_id: data?.data?.id,
    count: 1,
    product: {
      cover: `${data?.data?.images[0]?.directory}/${data?.data?.images[0]?.file_name}`,
      price: data?.data?.price,
      product_name: data?.data?.product_name
    },
    id: data?.data?.id
  }



  const handleProduct = async () => {
    setLoading(true)
    try {
      const response = await postProduct(sendData)
      if (response.success) {
        toast.success('Səbətə əlavə edildi');
      }
      setBasketListsCount([...basketListsCount, sendDataSmall])
      setAddToCart(true)
      setShowBasket(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }

  }

  const handleOrderProduct = async () => {

    try {
      const response = await postProduct(sendData)
      setBasketListsCount([...basketListsCount, sendData])
      toast.success('Səbətə əlavə edildi');
      if (response.success) {
        navigate("/cart/cartlist")
      }
      setAddToCart(true)
    } catch (error) {
      console.log(error)
    }

  }


  const getAllBaskets = async () => {
    try {
      const response = await getBaskets()
      const data = await response?.data;
      setBasketLists(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if(isAuthenticated){
      getAllBaskets()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const checkId = basketLists?.some((basket) => basket.product_id === Number(id))
    setBasket(checkId)
  }, [basketLists, id])


  let dateString = data?.data?.created_at;
  let date = new Date(dateString);
  let formattedDate = '';
  if (!isNaN(date.getTime())) {
    const parts = date.toISOString().substring(0, 10).split("-");
    const outputDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    formattedDate += outputDate;
  }


  const adress = data?.data?.shop?.shop_information[0]?.address
  const phone1 = data?.data?.shop?.shop_information[0]?.phone1
  const phone2 = data?.data?.shop?.shop_information[0]?.phone2
  const price1 = data?.data?.price
  const price2 = data?.data?.old_price

  const parseData = new DOMParser().parseFromString(data?.data?.information, 'text/html');
  const textContent = parseData.documentElement.textContent;
  const brandName = textContent.replace(/(.*)&nbsp;&nbsp;.*/, '$1');
  let contentBrandName;
  if (brandName === "undefined" || brandName === undefined || brandName === null || brandName === 'null') {
    contentBrandName = ""
  } else {
    contentBrandName = brandName
  }


  let priceNew;
  if (price1 === '0.00' || price1 === 0.00 || price1 === null || price1 === "null" || typeof price1 === "undefined" || price1 === undefined) {
    priceNew = ""
  } else {
    priceNew = `${price1} ₼`
  }



  let priceOld;
  if (price2 === '0.00' || price2 === 0.00 || price2 === null || price2 === "null" || typeof price2 === "undefined" || price2 === undefined) {
    priceOld = ``
  } else {
    priceOld = `${price2} ₼`
  }

  let goShop;
  if (data?.data?.shop?.shop_type === 2) {
    goShop = `/shops/${data?.data?.shop_id}?shop_type=2&shop_id=${data?.data?.shop_id}`
  } else if (data?.data?.shop?.shop_type === 1) {
    goShop = `/dealers/${data?.data?.shop_id}?shop_type=1&shop_id=${data?.data?.shop_id}`
  }


  const pageLink = (data?.data?.breadcrumb && data?.data?.breadcrumb.length > 0) ? data?.data?.breadcrumb.map(bread => {
    return {
      link: `/products?category_id=${bread.id}`,
      content: bread?.category_name
    }
  }) : []

  const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/`
  const seo_image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`



  const page = {
    pages: [
      {
        link: "/products",
        content: " Bütün məhsullar",
      },
      ...pageLink,
      {
        link: `/products?category_id=${data?.data?.category?.id ?? ""}`,
        content: data?.data?.category?.category_name ?? '',
      }

    ],
  };


  const shopLogo = image_url + data?.data?.shop?.logo

  const title = `Brand - ${data?.data?.product_name}`;
  const keywords = `${data?.data?.product_name ? data?.data?.product_name : ''}`
  const description = `${data?.data?.product_name ? data?.data?.product_name : ''}`
  const image = `${data?.data?.images[0]?.file_name ? seo_image_url + data?.data?.images[0]?.directory + '/' + data?.data?.images[0]?.file_name : ''}`


  return (
    <>
      <Meta title={title} keywords={keywords} description={description} image={image} />
      <Menu />
      <Breadcrumb page={page} />
      <section className={`container ${classes.detail__container}`}>
        <DetailSlider data={data?.data} />
        <DetailSliderMobil data={data?.data} />
        <div className={classes.detailInfo}>
          <div className={classes.detialText}>
            <div >
              <h3>{data?.data?.product_name}  {data?.data?.price !== 0.0 && <p className={classes.detial_p}>
                <strong className={classes.price}>{priceNew}</strong>
                <b className={`${classes.discount} ${classes.discountDemo}`}>{priceOld}</b>
              </p>}</h3>
              {contentBrandName ? <p>{contentBrandName}</p> : ""}
            </div>
            <div className={classes.d_flex}>
              <h3>
                <strong className={`${classes.price}`}>{priceNew} </strong>
                <b className={classes.discount}>{priceOld}</b>
              </h3>
            </div>
            <div className={classes.oem__date}>
              <div className={classes.detail_flex}>
                <p>OEM: <strong>{data?.data?.product_numbers[0]?.part_number}</strong></p>
                <p>Məhsulun nömrəsi: <strong>{data?.data?.id}</strong></p>
              </div>
              <div className={classes.detail_flex2}>
                <p>Yerləşmə tarixi: <strong>{formattedDate}</strong></p>
                <p>Baxış sayı: <strong> {data?.data?.views}</strong></p>
              </div>
            </div>
            <Link to={goShop} className={classes.logoShop}>
              {!isFetching && <img src={shopLogo} alt="" />}
            </Link>
          </div>
          <Detailtable data={data?.data} />

        </div>
        <div className={classes.detailMap}>
          <div className={classes.buttons}>
            <Link className={`btn  ${classes.btn__common_call} `} disabled={addToCart} onClick={handleOrderProduct}>{HomeIcon} Yerində al</Link>

            {!basket && (
              <button
                className={`btn btn__primary ${classes.btn__common_call} ${classes.green_btn} `}
                disabled={addToCart}
                onClick={handleProduct}
              >
                {addToCart ? "Əlavə edildi" : loading ? "Səbətə əlavə et..." : "Səbətə əlavə et"}
              </button>
            )}
            {basket && (
              <button className={`btn btn__primary`} disabled={true}>
                Əlavə edilib
              </button>
            )}

            <button className={`btn  ${classes.btn_call} ${classes.btn__common_call}`} onClick={openModal}>Zəng et</button>
            <Link to={`https://api.whatsapp.com/send?phone=${phone1}`} target='_blank' className={`btn  ${classes.btn_call} ${classes.btn__common_call}`}>Whatsapp</Link>
          </div>
          {/* <p><Link to={`tel:+994 ${phone1}`}> <BsTelephoneFill /> {phone1} {phone2 && "/"} {phone2}</Link></p> */}
          <div className='btnFlex btnFlexTop'>
            <p><Link style={{ alignItems: "center", lineHeight: "12px" }}>{LocationIcon}{adress}</Link></p>
            {/* <Link className={classes.showMap}> Show on map</Link> */}
          </div>

          <div className="map-wrap">
            <div ref={mapContainer} className="map" />
          </div>


          {/* <div className='btnFlex btnFlexTop'>
            <p> <Link onClick={() => setHeart(!heart)} style={{alignItems:"center"}}><span><FaHeart color={heart ? '#CF3434' : " #D9D9D9"} /></span> Seçilmişlərə əlavə et</Link></p>
            <Link className={classes.complaint}><CgDanger /> Şikayət et</Link>
          </div> */}
        </div>
        <ModalCall phone1={phone1} phone2={phone2} isModalOpenCall={isModalOpenCall} setIsModalOpenCall={setIsModalOpenCall} />
      </section>

      <SimilarProduct category_id={data?.data?.category_id} product_id={productId} />
      {/* <section className={classes.banerDetail}>
        <img src={img} alt="" />
      </section> */}


      <ToastContainer position="bottom-right" />
    </>
  )
}

export default Detail