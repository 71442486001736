import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import classes from "./mobil-nav.module.css"
import { AiFillHome } from "react-icons/ai"
import { IoStorefrontSharp } from "react-icons/io5"
import { FaCarAlt } from "react-icons/fa"
import { TbAwardFilled } from "react-icons/tb"
import { ModalNav } from '../modalNavbar/modalNav'

const MobilNavbar = () => {
  const [isModalOpenNav, setIsModalOpenNav] = useState(false);
  const openModal = () => {
    setIsModalOpenNav(true);
  };
  const [index, setIndex] = useState(1);
  const [isVisible, setIsVisible] = useState(true);
  const [prevScrollY, setPrevScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setIsVisible(prevScrollY > currentScrollY); 
    setPrevScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevScrollY]);

 

  const mobilNav = [
    {
      id: 1,
      url: "/",
      icon: <AiFillHome fontSize="2.5rem" />,
      text: 'Əsas'
    },
    {
      id: 2,
      url: "/dealers",
      icon: <TbAwardFilled fontSize="2.5rem" />,
      text: 'Diler'
    },
    {
      id: 3,
      url: null,
      icon: <button onClick={openModal}>+</button>,
      text: 'Mağaza aç',
      class: "ads"
    },
    {
      id: 4,
      url: "/shops",
      icon: <IoStorefrontSharp fontSize="2.5rem" />,
      text: 'Mağaza'
    },
    {
      id: 5,
      url: "/licenseplate",
      icon: <FaCarAlt fontSize="2.5rem" />,
      text: 'Qeydiyyat Nişanı'
    }
  ]

  return (
    <>
      {isVisible && <section className={classes.mobilNav} >
        <ul className='container'>
          {
            mobilNav.map((item) => (
              <li key={item.id}>
                <Link to={item.url} onClick={() => setIndex(item.id)} className={`${item.id === index ? classes.activeNav : ""} ${item.id === 3 && classes.ads}`}>
                  {item.icon}
                  <span> {item.text}</span>
                </Link>
              </li>
            ))
          }

        </ul>
        <ModalNav isModalOpenNav={isModalOpenNav} setIsModalOpenNav={setIsModalOpenNav} />
      </section>}
    </>
  )
}

export default MobilNavbar

