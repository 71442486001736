import { useQuery } from "@tanstack/react-query";
import { getCarMakes } from "../../services/carMakeService";

export const useAllCarMakes = () => {
    const params={order:"name", direction:"asc",id_car_type:1}
    const { data, isError, error, refetch } = useQuery({
        queryKey: ["car_makes"],
        queryFn: () => getCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error , refetch};
};

export const usePopularCarMakes = () => {
    const params={popular:1 , direction:"asc"}
    const { data, isError, error } = useQuery({
        queryKey: ["popular_car_makes"],
        queryFn: () => getCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
};

export const useCarmake = (params) => {
    const { data, isError, error } = useQuery({
        queryKey: ["car_make", params],
        queryFn: () => getCarMakes(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
}