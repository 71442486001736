import { useEffect } from "react";
import { createContext } from "react";
import React, { useState } from "react";

export const MenuContext = createContext();

const MenuContextProvider = ({ children }) => {

  const [active, setActive] = useState(false);
  const [showBasket,setShowBasket]=useState(false)

  const store = {
    active,
    setActive,
    showBasket,
    setShowBasket
  };
 
  useEffect(() => {
    if (showBasket) {
      const timeout = setTimeout(() => {
        setShowBasket(false);
      }, 3000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [showBasket]);

  return <MenuContext.Provider value={store}>{children}</MenuContext.Provider>;
};

export default MenuContextProvider;
