import React, { memo, useState } from 'react'
import classes from "./brand.module.css"
import Tabpanel from '../../../components/Tabpanel/index'
import Boxes from "../../../components/ui/boxes/boxes"
import { Link } from 'react-router-dom'
import { useAllCarMakes, usePopularCarMakes } from '../../../hooks/query/carMake'
import MobilBrand from './mobilBrand'


const Brand = () => {
  
  const {data: all_car_makes}=useAllCarMakes();
  const {data: popular_car_makes} =  usePopularCarMakes();

  const [brandIndex, setBrandIndex] = useState(0);

  const img_url_car_make = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/"
  
 

  return (
    <section className={`container ${classes.brand}`}>
      <h3 className="text-title">Markalar</h3>
      <Tabpanel brand={true} setBrandIndex={setBrandIndex} brandIndex={brandIndex}/>
      <MobilBrand brand={true} setBrandIndex={setBrandIndex} brandIndex={brandIndex}/>
      <div  className={` ${brandIndex===1 ? classes.allBrand : classes.brand__container} brandsList `}>
        {
          brandIndex===0 && popular_car_makes?.data?.map((car_make)=>(
            <Boxes
             key={car_make.id} 
             name={car_make.name} 
             image={img_url_car_make+car_make?.logo} 
             onClickLink={`/products?car_make_id=${car_make.id}`}
             />
          ))
        }
        {
         brandIndex===1 && all_car_makes?.data?.map((item) => (
            <li key={item.id}>
              <Link to={`/products?car_make_id=${item.id}`}>{item.name}</Link>
            </li>
          ))
        }

      </div>

    </section>
  )
}

export default memo(Brand)

