import React, { useDeferredValue, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import classes from "./shop.module.css";
// import Select from "react-select";
import Shopcard from "./shopcard/shopcard";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../components/ui/menu";
import { useShops } from "../../hooks/query/shop";
import Meta from "../../components/ui/meta";
import { Link } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";


const Shop = () => {
    console.log("shop render")
    const { data } = useShops(2);
    const [name, setName] = useState("");
    const deferredName = useDeferredValue(name);

    const handleOnChange = (event) => {
        setName(event.target.value);
    };

    const list = useMemo(() => {
        if (deferredName) {
            return data?.data?.filter((shop) => shop.name.toLowerCase().includes(deferredName.toLowerCase()));
        }

        return data?.data;
    }, [deferredName, data?.data]);

    const page = {
        pages: [
            {
                link: "/shops",
                content: "Mağazalar",
            },
        ],
    };

 
    const title= "Brand - Mağazalar" ;

    return (
        <>
            <Meta title={title}/>
            <Menu />
            <Breadcrumb page={page} none='dnone'   />
            <section className={`container ${classes.shop__container} `}>
            <Link className={`${classes.lnone}`} to="/"><SlArrowLeft color='#181818' fontSize="1.6rem" /></Link>
          <h1 className= {`${classes.head}`} >
             Mağazalar</h1>
                <div className="shop__search">
                    <form>
                        <input type="text" onChange={handleOnChange} placeholder="Mağaza axtar..." />
                        <button>
                            <FiSearch color="#D4252A" fontSize="2rem" />
                        </button>
                    </form>
                </div>
                <div className={classes.shop__list}>

                  
                    <div className={classes.shop__list__items}>
                        {list?.map((shop) => (
                            <Shopcard key={shop.id} shop={shop} />
                        ))}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Shop;


