import React from 'react'
import Breadcrumb from '../../components/ui/breadcrumb/breadcrumb';
import Menu from '../../components/ui/menu';
import { AiFillCar } from "react-icons/ai"
import { Lock, experince } from "../../assets/icons/Icons"
import { HiLocationMarker } from "react-icons/hi"
import { Link, useParams } from 'react-router-dom';
import { useRepairer } from '../../hooks/query/repairers';
import { BsFillTelephoneFill } from "react-icons/bs"

const RepairerDetail = () => {

  const { slug } = useParams()

  const { data } = useRepairer(slug)

  const position = data?.data?.position_categories.map((category) => {
    return <p className='mobilNone' key={category.id}>{Lock} {category.position_category.name}</p>
  })

  const name = `${data?.data?.name} ${data?.data?.surname} `

  const url_image = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/storage/";

  const parseData = new DOMParser().parseFromString(data?.data?.description, 'text/html');
  const textContent = parseData.documentElement.textContent;
  const repairDesciption = textContent.replace(/(.*)&nbsp;&nbsp;.*/, '$1');

  let repairerInfo;
  if (repairDesciption === "undefined") {
    repairerInfo = ""
  } else if (repairDesciption === 'null' || repairDesciption === null) {
    repairerInfo = ""
  }else{
    repairerInfo=repairDesciption
  }
 

  const page = {
    pages: [
      {
        link: "/ustalar",
        content: "Ustalar",
      },
      {
        content: name,
      }
    ],
  };

  const carMakes = data?.data?.position_categories.map((item) => item?.car_makes.map((car) => (
       <p className='mobilNone' key={item.id}><AiFillCar />  {car?.car_make_id === 0 ? 'Bütün Markalar' : car?.car_make?.name}</p>
  )))

 
  return (
    <section className='container__bg'>
      <Menu />
      <Breadcrumb page={page} />
      <section className='service__container container' >
        <div className='service__left'>
          <img className='service__img' src={url_image + data?.data?.photo} alt="" />
          <h3>{name}</h3>
          <div className='service__info'>
            {/* <p><AiFillStar/> 4.8</p> */}
            {position}
            {carMakes}
            <p>{experince} Təcrübə {data?.data?.experience} il</p>
            <p><Link to={`tel:+994 ${data?.data?.number}`} ><BsFillTelephoneFill /> {data?.data?.number}</Link></p>
            <p className='mobilLocation-100'><Link target="_blank" to={`https://www.google.com/maps/search/${data?.data?.address}`}><HiLocationMarker /> {data?.data?.address}</Link></p>
          </div>
          <div className='service__works service__works__mobil'>
            <h3>Gördüyü işlər </h3>
            {data?.data?.position_categories.map((item) => (
              <p key={item.id}>{item?.position_category?.name}  <span>{item?.car_makes?.car_make_id === 0 ? 'Bütün markalar' : item?.car_makes?.car_make?.name}</span> </p>
            ))}
          </div>
          <div className='service__note'>
            <h2>QEYD:</h2>
            <p>{repairerInfo}</p>

          </div>
        </div>
        <div className='service__right'>
          <div className='service__slider'>
            {/* <div className="slideShow">
                    <SlideShow/>         
                </div>  */}
            <div className='service__works'>
              <h3>Gördüyü işlər </h3>
              {data?.data?.position_categories.map((item) => (
                <p key={item.id}>{item?.position_category?.name}  <span>{item?.car_makes?.car_make_id === 0 ? 'Bütün markalar' : item?.car_makes?.car_make?.name}</span></p>
              ))}
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}

export default RepairerDetail



