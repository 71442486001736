import React from 'react'
import { AiFillHeart } from 'react-icons/ai';
import { BiChevronRight } from 'react-icons/bi';
import { FaShoppingCart, FaUser, FaWallet } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import classes from "../../pages/carts/carts.module.css"
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';


const CartTabPanel = ({activeTab}) => {

    const { setUser,user, setIsAuthenticated,visibleMobil,setVisibleMobil } = useContext(AuthContext)

    const handleLogout = () => {
        setIsAuthenticated(false)
        setUser(null)
        localStorage.removeItem('token');
        localStorage.removeItem('userData');

      }
 

    return (
        <div className={!visibleMobil ? 'container__tabs' : " activeTabs"}>
            <div className='tabs__mobil__user'>
                <span className={classes.tabUser}> <FaUser color='#C8C8C8' /> </span>
                <div>
                    <h3>{user?.name} {user?.surname}</h3>
                    <p>{user?.email}</p> </div>
                <BiChevronRight />
            </div>
            <ul className='tabs'>
            {
              tabs.map((tab) => (
                <li className='tabLi' key={tab.id}>
                  <span className="tab__mobil__icon">{tab.icon}</span>
                  <Link to={`/cart/${tab.url}`} onClick={()=>setVisibleMobil(!visibleMobil)}  className={activeTab === tab.url ? "activeTab" : ""}>{tab.title} </Link>
                </li>
              ))
            } 
             <li className='tabLi' >
                  <span className="tab__mobil__icon"></span>
                  <Link to={`/`} onClick={handleLogout}  > Çıxış </Link>
                </li>

          </ul>
            <button className='btn btn__pink dangerText mobilLogout' onClick={handleLogout}>Hesabdan çıx</button>
        </div>
    )
}

export default CartTabPanel

const tabs = [
    {
        id: 1,
        title: 'Səbətim',
        url: "cartlist",
        icon: <FaShoppingCart color="#D4252A" cursor="pointer" />
    },
    {
        id: 2,
        title: 'Sifarişlərim',
        url: "orderlist",
        icon: <FaWallet
            color="#D4252A" cursor="pointer" />
    },
    {
        id: 3,
        title: 'Seçilmiş məhsullar',
        url: "wishlist",
        icon: <AiFillHeart color="#D4252A" cursor="pointer" />
    },
    {
        id: 4,
        title: 'Kartlarım',
        url: "mycards",
        icon: <FaWallet color="#D4252A" cursor="pointer" />
    },
    {
        id: 5,
        title: 'Balansım',
        url: "mybalance",
        icon: <FaWallet color="#D4252A" cursor="pointer" />
    },
    {
        id: 6,
        title: 'Keşbek',
        url: "cashback",
        icon: <FaWallet color="#D4252A" cursor="pointer" />
    },
    {
        id: 7,
        title: 'Şəxsi məlumat',
        url: "privateinfo",
        icon: <FaWallet color="#D4252A" cursor="pointer" />
    }
];
