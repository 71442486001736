import request from "../helpers/api/request";

export const getWheelHeights = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/heights${queryString}`);

    return response.data;
};


export const getWheelDiameters= async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/diameters${queryString}`);

    return response.data;
};


export const getWheelWidths= async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/widths${queryString}`);

    return response.data;
};