import { Link } from "react-router-dom";
import classes from "./404.module.css";
import { BsArrowRightShort } from "react-icons/bs";
import image from "../../assets/images/notfound.png"

const NotFound = () => {


  return (
    <section className={`container ${classes.container404}`}>
      <div className={classes.left}>
        <h1>404</h1>
        <h3>Səhifə tapılmadı</h3>
        <Link to="/">
            Ana səhifəyə qayıt <BsArrowRightShort className={`icon-14 ${classes.right_arrow}`} />
        </Link>
      </div>
      <div className={classes.right}>
        <img src={image} alt=''/>
      </div>
    </section>

  )
}

export default NotFound