import React from 'react'
import classes from "./cart-item.module.css"
import { Link } from 'react-router-dom'
import { deleteBasketItem, updateBasketItem } from '../../../services/basketService'
import { ToastContainer, toast } from 'react-toastify';
import { useContext } from 'react';
import { AuthContext } from '../../../context/AuthContext';


const CartItem = (props) => {
  const { basketListsCount ,setBasketListsCount} = useContext(AuthContext)
  const {data, item  ,setBasketLists , handleCheckboxChange  , setSelectedItems,selectedItems }=props
 
  const img_url = "https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/"

  const productNumber = item?.product?.product_numbers?.map((item) => item?.oem)
  const carMakes = item?.product?.car_makes.map((item) => item.name);
  const category = item?.product?.category?.category_name;
  const uniqeCarMakes = [...new Set(carMakes)]


  const removeCartItem = async (id) => {

    const newItem=data?.filter((item)=> item.id!==id)
    const removeSelected=selectedItems?.filter((item)=>item.id!==id)
    const removeBasket=basketListsCount?.filter((item)=>item.id !==id )
    setBasketLists(newItem)
    setSelectedItems(removeSelected)
    setBasketListsCount(removeBasket)
    toast.success('Məhsul silindi');
    try {
       await deleteBasketItem(id)
     } catch (error) {
      console.log(error)
    }

  }

  

  const updateCartItem = async (cart, get) => {
 
    const newItem = data?.find((item) => item.id === cart.id);
    
    let countItem
    if(get){
      countItem=newItem.count + 1
    }else{
      countItem=newItem.count - 1
      if(countItem<1){
        countItem=1
      }
    }
    

    if (newItem) {
      const updatedItem = {
        ...newItem,
        count: countItem,
      };
  
      setBasketLists((prevLists) => {
        const updatedLists = prevLists.map((item) => {
          if (item.id === updatedItem.id) {
            return updatedItem;
          }
          return item;
        });
        return updatedLists;
      });

      setSelectedItems((prevItems) =>
      prevItems.map((item) =>
        item.id === cart.id ? { ...item, count: countItem } : item
      )
    );

      try {
        await updateBasketItem(updatedItem);
       
      } catch (error) {
        console.log(error);
      }
    }
  };
  


  return (
    <>
      <div className={`${classes.cartItem}`}>
        <input type="checkbox" className={classes.check} onChange={(e)=>handleCheckboxChange(e,item)}/>
        <p className={`${classes.image}`}><img src={img_url + item?.product?.cover} alt=''/></p>
        <div className={classes.item__2}>
          <h5>{item?.product?.product_name}</h5>
          <p className={classes.carLists}>{uniqeCarMakes.map((car,i)=><span key={i}>{car}</span>)}</p>
        </div>
        <p className={classes.item__3}>OEM kod: {productNumber}</p>
        <h5>{category}</h5>
        <div className={`${classes.btns}`}>
          <button onClick={()=>updateCartItem(item, false)}>-</button>
          <button > {item?.count} </button>
          <button onClick={()=>updateCartItem(item, true)}>+</button>
        </div>
        <h5>{item?.product?.price} ₼</h5>
        {/* <div className={`${classes.promocod}`}>
          <span>Promokod</span>
          <input type="text" />
        </div> */}
        <button className={`${classes.remove}`} onClick={() => removeCartItem(item?.id)}> X</button>
      </div>
   
   
   
      <div className={classes.mobilCartItem}>
        <input type="checkbox" className={classes.mobilCheck} onChange={(e)=>handleCheckboxChange(e,item)}/>
        <div className={classes.mobilCartLeft}>
          <Link className={`${classes.image}`}><img src={img_url + item?.product?.cover} alt=''/></Link>
          <div className={classes.mobilCartName}>
            <h5>{item?.product?.product_name}</h5>
            <p className={classes.carLists}>{uniqeCarMakes.map((car,i)=><span key={i}>{car}</span>)}</p>
            <p>OEM kod: {productNumber}</p>
          </div>
          {/* <div className={`${classes.mobilPromocod}`}>
            <span>Promokod</span>
            <input type="text" />
          </div> */}
        </div>
        <div className={classes.mobilCartRight}>
          <button className={classes.mobilRemove} onClick={() => removeCartItem(item?.id)}> X</button>
          <div className={`${classes.btns}`}>
            <button onClick={()=>updateCartItem(item, false)}>-</button>
            <button> {item?.count} </button>
            <button onClick={()=>updateCartItem(item, true)}>+</button>
          </div>
          <h5>{item?.product?.price} ₼</h5>
        </div>
      </div>

      <ToastContainer position="bottom-right"/>
    </>
  )
}

export default CartItem