import Select from "react-select";
import { FiSearch } from "react-icons/fi";
import { CustomStyles } from "../../helpers/contants/s3";
import classes from '../ui/filter/filter.module.css'
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useWheelDiameters, useWheelHeight, useWheelWidths } from "../../hooks/query/wheelHeight";


const WheelRimsFilter = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const categoryId = Number(searchParams.get("category_id"))
    const [wheelHeightId, setwheelHeightId] = useState(searchParams.get("height_id") ?? 0);
    const [wheelDiameterId, setwheelDiameterId] = useState(searchParams.get("diameter_id") ?? 0);
    const [wheelWidthId, setwheelWidthId] = useState(searchParams.get("width_id") ?? 0);
    const [isFixed, setIsFixed] = useState(false);
    const ref = useRef()


    const { data: wheelHeightData } = useWheelHeight()
    const { data: wheelDiameters } = useWheelDiameters()
    const { data: wheelWidths } = useWheelWidths()

    const wheelHeight = wheelHeightData?.data?.map((item) => ({
        value: item.id,
        label: item.value,
    }));

    const wheelDiameter = wheelDiameters?.data?.map((item) => ({
        value: item.id,
        label: item.value,
    }));

    const wheelWidth = wheelWidths?.data?.map((item) => ({
        value: item.id,
        label: item.value,
    }));

    const handleWheelHeight = (e) => {
        setwheelHeightId(e.value)
    }

    const handleWheelDiameter = (e) => {
        setwheelDiameterId(e.value)
    }

    const handleWheelWidth = (e) => {
        setwheelWidthId(e.value)
    }

    const handleSubmitWheelInfo = (e) => {
        e.preventDefault();
        const queryParams = Object.fromEntries(searchParams.entries());

        queryParams.height_id = wheelHeightId;
        queryParams.diameter_id = wheelDiameterId;
        queryParams.width_id = wheelWidthId;
        delete queryParams.product_number;
        delete queryParams.car_make_id;
        delete queryParams.car_model_id;
        delete queryParams.car_generation_id;
        delete queryParams.order;
        delete queryParams.direction;

        const updatedSearchParams = new URLSearchParams(queryParams);
        setSearchParams(updatedSearchParams);

        if (location.pathname === "/") {
            navigate(`/products?${updatedSearchParams.toString()}`);
        } else {
            navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
        }

    };


    const handleSubmitDiscInfo = (e) => {
        e.preventDefault();
        const queryParams = Object.fromEntries(searchParams.entries());

        queryParams.diameter_id = wheelDiameterId;
        delete queryParams.product_number;
        delete queryParams.car_make_id;
        delete queryParams.car_model_id;
        delete queryParams.car_generation_id;
        delete queryParams.order;
        delete queryParams.direction;

        const updatedSearchParams = new URLSearchParams(queryParams);
        setSearchParams(updatedSearchParams);

        if (location.pathname === "/") {
            navigate(`/products?${updatedSearchParams.toString()}`);
        } else {
            navigate(`${location.pathname}?${updatedSearchParams.toString()}`);
        }

    };


    useEffect(() => {
        const handleScroll = () => {
            const currentFilter = ref.current.offsetTop;
            if (window.scrollY < 150) {
                setIsFixed(false);
            } else if (window.scrollY >= currentFilter) {
                setIsFixed(true);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);


    return (
        <section className={`${classes.filterFixed} ${isFixed ? classes.stickyFilter : ''}`}>
            <div className={`container ${classes.filter__container} `} ref={ref}>
              { categoryId === 45 && <form onSubmit={handleSubmitWheelInfo}>
                    <Select
                        className={classes.selectItem}
                        placeholder={"En"}
                        onChange={handleWheelWidth}
                        value={wheelWidth?.find((option) => option.value === wheelWidthId)}
                        styles={CustomStyles}
                        options={wheelWidth}
                    />
                    <Select
                        className={classes.selectItem}
                        placeholder={"Hündürlük"}
                        styles={CustomStyles}
                        options={wheelHeight}
                        onChange={handleWheelHeight}
                        value={wheelHeight?.find((option) => option.value === wheelHeightId)}
                    />
                    <Select
                        className={classes.selectItem}
                        placeholder={"Radius"}
                        onChange={handleWheelDiameter}
                        value={wheelDiameter?.find((option) => option.value === wheelDiameterId)}
                        options={wheelDiameter}
                        styles={CustomStyles}
                    />

                    <button className={classes.btn__filter__search}>
                        <FiSearch fontSize="2rem" />
                    </button>
                </form>}

                { categoryId === 44 && <form onSubmit={handleSubmitDiscInfo}>
                
                    <Select
                        className={classes.selectItem}
                        placeholder={"Radius"}
                        onChange={handleWheelDiameter}
                        value={wheelDiameter?.find((option) => option.value === wheelDiameterId)}
                        options={wheelDiameter}
                        styles={CustomStyles}
                    />

                    <button className={classes.btn__filter__search}>
                        <FiSearch fontSize="2rem" />
                    </button>
                </form>}
            </div>
        </section>
    );
};

export default WheelRimsFilter;

