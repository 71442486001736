import React, { memo } from "react";
import classes from "./breadcrumb.module.css";
import { FaAngleRight } from "react-icons/fa";
import { Link } from "react-router-dom";

const Breadcrumb = ({ page , none}) => {
    const pageLink = page.pages;

    return (
        <section className={`${classes.page_title} ${classes[none]} `} >
            <div className={`${classes.page__link} container `}>
                <Link to="/">
                    Ana Səhifə
                    <span>
                        <FaAngleRight />
                    </span>
                </Link>

                {pageLink?.map((item, i, arr) => (
                    <Link to={item.link} key={i}>
                        {item.content}
                        {i + 1 !== arr.length && (
                            <span>
                                <FaAngleRight />
                            </span>
                        )}
                    </Link>
                ))}
            </div>
        </section>
    );
};

export default memo(Breadcrumb);
