import React from 'react'
import { svgCheck } from '../../assets/icons/Icons'
import "./order.css"
import Meta from '../../components/ui/meta';

const PaymentSuccess = () => {

  const title = "Brand - Ödəniş uğurlu";

  return (
    <>
      <Meta title={title} />
      <section className={`container order__container`}>
        <div className='order__item'>
          <p>{svgCheck}</p>
          <h3>Ödəniş uğurlu oldu</h3>

        </div>
      </section>
    </>
  )
}

export default PaymentSuccess