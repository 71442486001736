import React, { memo } from "react";
import Select from "react-select";
import classes from "../../../components/Tabpanel/tabpanel.module.css";
import {CustomStyles} from "../../../helpers/contants/s3"

const MobilBrand = (props) => {
    const { setBrandIndex}=props
 
    const brands=[
        {
            value:0,
            label:'Populyar markalar'
        },
        {
            value:1,
            label:'Bütün markalar'
        }
    ]
   const handleChange=(e)=>{
    setBrandIndex(e.value)
   }

   

    return (
        <>
            <Select
                className={classes.selectItem}
                onChange={handleChange}
                placeholder={"Markalar"}
                options={brands}
                styles={CustomStyles}
            />
        </>
    );
};

export default memo(MobilBrand);

