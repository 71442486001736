import { useQuery } from "@tanstack/react-query";
import { getCities, getCityNumbers } from "../../services/citiesService";


export const useCities = () => {
    const params = { direction: "asc" }

    const { data, isError, error } = useQuery({
        queryKey: ["cities"],
        queryFn: () => getCities(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error };
};


export const useCitiesNumber = () => {
    const params = { direction: "asc" }

    const { data, isError, error } = useQuery({
        queryKey: ["citynumber"],
        queryFn: () => getCityNumbers(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error };
};