import React from "react";
import { useOfficialProducts, useShopProducts } from "../../../hooks/query/products";
import { memo } from "react";
import ProductSlider from "./ProductSlider"


const SliderCard = () => {
   
    const { data: shopSliderData, isFetching:shopFecthing } = useShopProducts();
    const { data: officialSliderData, isFetching:officialFetching } = useOfficialProducts();

    return (
        <section className={`container`}>
            <ProductSlider link={`/products?shop_type=2`} data={shopSliderData} isFetching={shopFecthing} slide_speed={1500} title="Mağaza" />
            <ProductSlider link={`/products?shop_type=1`} data={officialSliderData} isFetching={officialFetching} slide_speed={1000} title="Rəsmi diler" />
        </section>
    );
};

export default memo(SliderCard);
