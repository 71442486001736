export const S3_LINK = 'https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/';

export const CustomStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? '#D4252A' : '#CCCCCC',
      boxShadow: state.isFocused ? '0 0 0 1px #D4252A' : null,
      '&:hover': {
        borderColor: state.isFocused ? '#D4252A' : '#CCCCCC', 
      },
     
    }),
  };