import { useQuery } from "@tanstack/react-query";
import { getYoutube } from "../../services/youtubeService";

export const useYoutube = (limit) => {

    const params = { order: "position" };
    if (limit) {
        params.limit = 5;
    }
    const { data, isError, error } = useQuery({
        queryKey: ["youtube"],
        queryFn: () => getYoutube(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
        refetchOnMount:"always"
    });

    return { data, isError, error };
};
