import React, { useState } from 'react';
import Slider from 'react-slick';
import "../../assets/styles/slider.css"

export default function DetailSlider({ data }) {
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  const image_url = `https://s3.eu-central-1.amazonaws.com/uploads.avtopro.general/`

  return (
    <div className="detailSlider">
      <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} className="mainSlider">
        {
          data?.images?.map((item) => (
            <div className='oneImg' key={item.id}>
              <img src={image_url + item?.directory + "/" + item?.file_name} alt="img" />
            </div>
          ))
        }
      </Slider>
      <Slider
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        slidesToShow={data?.images?.length}
        swipeToSlide={true}
        focusOnSelect={true}
        className="smallImages"
      >
        {
          data?.images?.map((item) => (
            <div className='smallImg ' key={item.id}>
              <img src={image_url + item?.directory + "/" + item?.file_name} alt="img" />
            </div>
          ))
        }

      </Slider>
    </div>
  );
}
