import React from "react";
import Modal from "../../modals/Modal";
import { BsTelephoneFill } from 'react-icons/bs';
import { Link } from "react-router-dom";

export const ModalCall = ({ isModalOpenCall, setIsModalOpenCall,phone1,phone2 }) => {

    const screenSize = window.innerWidth
    const closeModal = () => {
        setIsModalOpenCall(false);
    };

    const formattedNumber = String(phone1).slice(1); 
    const formattedNumber2 = String(phone2).slice(1); 
    

    return (
        <Modal isOpen={isModalOpenCall} onClose={closeModal} width={340} height={screenSize > 600 ? 35 : 32}>
            <div className="call__number"style={callNumber} >
               {phone1 !=null && <p><Link to={`tel:+994${formattedNumber}`} style={numberStyle}> <BsTelephoneFill color="#BBBBBB" />{phone1}</Link></p>}
               {phone2 !=null && <p><Link to={`tel:+994${formattedNumber2}`}   style={numberStyle}   > <BsTelephoneFill color="#BBBBBB" />{phone2}</Link></p>}
            </div>
        </Modal>
    );
};



const numberStyle = {
    display: "flex",
    alignItems: "center",
    gap: "1rem",
    color: "#434242",
    fontSize: "1.7rem"
}

const callNumber = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    gap: "2rem"
}