import request from "../helpers/api/request";

export const productService = (id) => {
    return request.get("/products").then((res) => res.data);
}


export const getProducts = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/products${queryString}`);

    return response.data;
}


export const getProduct = async (id) => {
    const response = await request.get(`/products/${id}`);
    return response.data;
};

export const getProductsCount = async () => {
    const response = await request.get(`/products_count`);
    return response.data;
};


export const autoComplete = async (query) => {
    const response = await request.get(`/auto_complete?search=${query}`)
    return response.data;
}

export const calculateCourierPrice = async (data) => {
    const response = await request.post(`/account/calculate_delivery_price`, data)
    
    return response.data;
}