import request from "../helpers/api/request";

export const getRepairers = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/repairers${queryString}`);

    return response.data;
};


export const getRepairer = async (slug) => {
    const response = await request.get(`/repairers/${slug}`);
    return response.data;
};


export const getPositionCategory = async (query_params = {}) => {
    const params = new URLSearchParams(query_params);
    const queryString = "?" + params.toString();
    const response = await request.get(`/position_categories${queryString}`);

    return response.data;
};