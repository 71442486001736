import React, { useContext, useEffect, useState } from 'react'
import CartItem from '../cartItem/CartItem'
import Modal from '../../../modals/Modal'
import classes from "../carts.module.css"
import { HiChevronLeft } from 'react-icons/hi'
import { createOrder } from '../../../services/orderService'
import { svgCheck, svgCancel, courierIcon, PashCashIcon } from '../../../assets/icons/Icons'
import { Link, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./yup";
import { calculateCourierPrice } from '../../../services/productService'
import YMap from './yandexMap'
import { AuthContext } from '../../../context/AuthContext'
import CartTabPanel from '../../../components/cartTab'
import { getBaskets } from '../../../services/basketService'
import Menu from '../../../components/ui/menu'


const Cartlist = () => {
 
  const { user,visibleMobil,setVisibleMobil,getAllBasketsCount } = useContext(AuthContext)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenCourier, setIsModalOpenCourier] = useState(false);
  const [isModalPayment, setIsModalPayment] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [total, setTotal] = useState();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [checked, setChecked] = useState(false);
  const [courierInfo, setCourierInfo] = useState(null)
  const [courierPrice, setCourierPrice] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [mapLng, setMapLng] = useState(null);
  const [mapLat, setMapLat] = useState(null);
  const screenSize = window.innerWidth;
  const [getCourierData, setGetCourierData] = useState([])
  const [basketLists,setBasketLists]=useState(null)
  const navigate = useNavigate();
 
  const getAllBaskets=async()=>{
    try {
      const response=await getBaskets()
      const data=await response?.data;
      setBasketLists(data)
    } catch (error) {
     console.log(error)
    }
}


useEffect(()=>{
   getAllBaskets()
   getAllBasketsCount()
   // eslint-disable-next-line react-hooks/exhaustive-deps
},[])

  useEffect(() => {
    const totalPrice = selectedItems.reduce((acc, curr) => acc + curr.price * curr.count, 0).toFixed(2)
    setTotal(totalPrice);
  }, [selectedItems]);

  useEffect(() => {
    if (user) {
      setFullname(user.name + " " + user.surname);
    }
  }, [user]);

  const handleCourier = () => {
    if (selectedItems.length < 1) {
      setChecked(!checked)
      setShowMessage(true)
    }
    const shopId = selectedItems[0].shop_id;
    const checkShopId = selectedItems.every((obj) => obj.shop_id === shopId);



    if (checkShopId) {
      setIsModalOpenCourier(true);
    } else {
      toast.error("Kuryer teyin etdiyiniz mehsullar eyni magazadan olmalidir");
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsModalOpenCourier(false);
    setChecked(false)
    setShowMessage(false)
    setIsModalPayment(false)
  };


  const handleCheckboxChange = (e, item) => {

    const data = {
      count: item?.count,
      id: item?.id,
      product_id: item?.product_id,
      price: Number(item?.product?.price),
      shop_id: item?.product?.shop_id
    }


    if (e.target.checked) {
      setSelectedItems((prevItems) => [...prevItems, data]);


    } else {
      setSelectedItems((prevItems) => prevItems.filter((selectedItem) => selectedItem.id !== data.id));

    }
  };


  const handleBuy = () => {

    if (selectedItems.length < 1) {
      setChecked(!checked)
      setShowMessage(true)
    } else {
      setIsModalPayment(true)
    }

  }


  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });


  const onSubmit = async (data) => {
   
    setGetCourierData(data)
    setCourierInfo(data)

    if (!(mapLat && mapLng)) {
      toast.error("Xəritədə ünvanı seçin")
      return;
    }

    const sendData = {
      shop_id: selectedItems[0].shop_id,
      weight: data.weight,
      delivery_latitude: String(mapLat),
      delivery_longitude: String(mapLng)
    }
  
    //send request and write price
    try {
      const response = await calculateCourierPrice(sendData);
     
      if (response?.data?.delivery_price === 0) {
        setIsModalOpenCourier(false)
        toast.error("Məlumatları düzgün daxil edin")
      } else {
        setCourierPrice(response?.data?.delivery_price)
        setCourierInfo(data)
        setTotal(Number(total) + response?.data?.delivery_price);
        setIsModalOpenCourier(false)

      }

    } catch (error) {
      console.log(error, "eeeerrr")
      if (error?.response?.status === 422) {
        toast.error(error?.response?.data?.message)
      } else {
        toast.error("Hesablama zamanı xəta baş verdi")
      }
      setIsModalOpenCourier(false)
    }

    reset();
  }


  const paymentOrder = async (e) => {

    const requestBody = {
      products: selectedItems.map(item => ({ count: item.count, id: item.product_id })),
      online_payment: e === "online" ? 1 : 0,
      has_courier: courierPrice ? 1 : 0,
    };

    if (courierPrice) {
      requestBody.couriers = [{ shop_id: selectedItems[0].shop_id, ...courierInfo, delivery_latitude: String(mapLat), delivery_longitude: String(mapLng) }];
    }


    setLoadingPayment(true)

    try {
      const response = await createOrder(requestBody);

      setTotal(0);
      setCourierInfo(null);
      setCourierPrice(null);
      setSelectedItems([]);
      setCourierInfo();

      if (e === "cash") {
        setIsModalOpen(true)

      }

      if (e === "online") {
        setIsModalPayment(false)
        window.location.href = response?.data?.payment_link
      }

    } catch (error) {
      if (error?.response?.status === 422) {
        toast.error(error?.response?.data?.message)
      } else {
        toast.error("Xeta baş verdi")
      }

    } finally {
      setIsModalPayment(false)
      setLoadingPayment(false)
    }


  }

  const handleLeftClick=()=>{
    setVisibleMobil(!visibleMobil)
  }

  const handleGoOrder=()=>{
    navigate("/cart/orderlist")
  }


  return (
    <>
    <Menu />
    <section className={`${classes.carts__container} container`}>
       <CartTabPanel activeTab='cartlist' />
      <div  className={visibleMobil ? 'container__tabs' : " activeTabs"}>
        <div className='mobil__title__cart'>
          <span className='left__mobil__icon'  onClick={handleLeftClick} ><HiChevronLeft fontSize="2rem" /></span>
          <h3> Səbətim</h3>
          <span></span>
        </div>
        <h3 className='mobil__none'>Səbətim</h3>

        {
          basketLists?.map((item) => (
            <CartItem
              key={item.id}
              item={item}
              handleCheckboxChange={handleCheckboxChange}
              data={basketLists}
              selectedItems={selectedItems}
              setBasketLists={setBasketLists}
              setSelectedItems={setSelectedItems}
            />
          ))
        }


        {/* <CartDetail /> */}
        <div className={classes.totalDelivery}>
          {courierPrice && <div className={classes.cartPrice}>
            <h3>Kuriyer haqqı</h3>
            <p className={classes.totalPrice}>{courierPrice} AZN</p>
          </div>}
          <div className={`${classes.cartPrice} ${classes.borderTotal} `}>
            <h3>TOTAL</h3>
            <p className={classes.totalPrice}>{total} AZN </p>
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}>
            {!courierPrice ? (
              <>
                <button className='btn btn__transparent ' onClick={handleBuy}>Yerinde al</button>
                <button className='btn LightBtn' onClick={handleCourier}>Kuriyer təyin et</button>
              </>
            ) : (
              <>
                <button className='btn btn__transparent ' onClick={handleCourier}>Düzəliş et</button>
                <button className='btn LightBtn ' onClick={handleBuy}>Tamamla</button>
              </>
            )}
          </div>
        </div>
        {/* <div className='nextButton'><button className='btn btn__primary' onClick={openModal}>Növbəti </button></div> */}


        <Modal isOpen={isModalOpen} removeX={true} width={340} height={screenSize > 600 ? 35 : 32}>

          <div className={`  ${classes.modalCart} ${classes.modalCartFont} ${classes.modalHeight}`}>
            <p>{svgCheck}</p>
            <h3>Sifarişiniz uğurla
              həyata keçdi!</h3>
            <p className='successText' onClick={handleGoOrder}>Sifarişə geri qayıt </p>
          </div>

        </Modal>

        {
          checked &&
          <Modal isOpen={showMessage} onClose={closeModal} width={340} height={screenSize > 600 ? 28 : 30}>
            <div className={`  ${classes.modalCart} ${classes.modalCartFont} ${classes.modalHeight}`}>
              <p>{svgCancel}</p>
              <h3> Məhsulu seçin</h3>
            </div>
          </Modal>
        }


        <Modal isOpen={isModalOpenCourier} onClose={closeModal} width={680} height={screenSize > 600 ? 90 : 90}>
          <div className={` ${classes.modalCart} ${classes.modalCourier}`}>
            <p>{courierIcon}</p>
            <h3>Kuriyer təyin et</h3>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={classes.formGroup}>
                <h3>Şəxsi məlumat</h3>
                <div className={classes.formItem}>
                  <span>Götürəcək şəxsin adı </span>
                  <input type="text" defaultValue={getCourierData?.name ? getCourierData?.name : fullname}  {...register("name")} />
                  <p className='dangerText'>{errors.name?.message}</p>
                </div>
                <div className={classes.formItem2}>
                  <span>Əlaqə nömrəsi</span>
                  <input type="text" defaultValue={getCourierData?.phone ? getCourierData?.phone : user?.phone} minLength={10} maxLength={10} placeholder="055222xxxx" {...register('phone')} />
                  <p className='dangerText'>{errors.phone?.message}</p>
                </div>
              </div>
              {/* <div className={classes.formGroup}>
              <h3>Ünvan məlumatları</h3>
              <div className={classes.formItem}>
                <span>Bölgə</span>
                <Select
                  placeholder={'Bölgə '}
                  className={classes.selectItem}
                  styles={CustomStyles}
                />
              </div>
              <div className={classes.formItem2}>
                <span>Rayon</span>
                <Select
                  placeholder={'Yasamal rayonu '}
                  className={classes.selectItem}
                  styles={CustomStyles}
                />
              </div>
            </div> */}

              <div className={classes.formItemOne}>
                <span>Email</span>
                <input type="email" defaultValue={getCourierData?.email ? getCourierData?.email : user?.email} {...register('email')} />
                <p className='dangerText'>{errors?.email?.message}</p>
              </div>

              {/* <div className={classes.formItemOne}>
              <span>Ünvan</span>
              <input type="text"  {...register('address')} />
              <p className='dangerText'>{errors.address?.message}</p>
            </div> */}

              {/* <Map setMapLng={setMapLng} setMapLat={setMapLat} /> */}

              <YMap setMapLng={setMapLng} setMapLat={setMapLat} register={register} errors={errors.address?.message} />

              <div className={classes.formItemOne}>
                <h5>Çəki</h5>
                <input type="number" defaultValue={getCourierData?.weight ? getCourierData?.weight : ""} step={"0.1"}   {...register('weight')} />
                <p className='dangerText'>{errors.weight?.message}</p>
              </div>
              <div className={classes.formItemOne}>
                <span>Şərh</span>
                <textarea rows={2}   {...register('delivery_instructions')} />
                <p className='dangerText'>{errors.delivery_instructions?.message}</p>
              </div>
              <button type='submit' className={`btn btn__primary ${classes.next}`}>Növbəti</button>
            </form>
          </div>

        </Modal>

        <Modal isOpen={isModalPayment} onClose={closeModal} width={450} height={screenSize > 600 ? 32 : 32}>
          <div className='payModal'>
            {
              !loadingPayment ? (
                <div className="modalItems ">
                  <div className='payItem' onClick={() => paymentOrder('online')}>
                    <Link>
                      <svg width="74" height="65" viewBox="0 0 74 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_182_3452)">
                          <path d="M73.9985 61.3676C73.7848 61.4185 73.8011 61.6275 73.7504 61.7694C73.0606 63.7183 71.5252 64.8818 69.481 64.9873C69.2891 64.9964 69.0953 64.9945 68.9034 64.9945C47.6395 64.9945 26.3738 64.9909 5.10987 65C3.05482 65.0018 1.4669 64.2437 0.498223 62.3748C0.136099 61.6766 0.014788 60.9149 0.014788 60.1295C0.0165986 52.6574 0.0202199 45.1835 0.0039243 37.7114C0.00211368 37.2296 0.136099 37.1241 0.597807 37.1241C12.6149 37.1369 24.632 37.1351 36.649 37.1296C37.0003 37.1296 37.2574 37.1732 37.4874 37.5168C40.7012 42.3055 45.0883 45.8052 49.8937 48.8395C50.5437 49.2504 51.2028 49.6504 51.8709 50.0322C54.2102 51.3702 56.6075 51.4666 58.9468 50.1285C63.3539 47.6069 67.4821 44.6981 70.9114 40.8929C71.833 39.8712 72.6804 38.7895 73.4553 37.6496C73.5929 37.4478 73.6599 37.1332 74.0003 37.1332C73.9985 45.2126 73.9985 53.2901 73.9985 61.3676ZM16.1909 46.4215C14.746 46.4215 13.3011 46.4179 11.8562 46.4234C10.3172 46.4288 9.27248 47.3651 9.26705 48.7323C9.25981 50.1031 10.3045 51.0593 11.8381 51.063C14.7514 51.0702 17.6647 51.0702 20.578 51.063C22.1025 51.0593 23.1473 50.0885 23.131 48.7159C23.1147 47.3742 22.0808 46.4325 20.5961 46.4234C19.1277 46.4143 17.6593 46.4215 16.1909 46.4215Z" fill="#D3D3D3" />
                          <path d="M74.0012 21.8981C73.6897 22.6817 73.7133 23.5271 73.6064 24.3416C72.741 31.0137 69.5814 36.3842 64.5045 40.6893C62.1253 42.7055 59.5379 44.4144 56.8582 45.9943C55.9945 46.5033 55.1363 46.567 54.2618 46.0561C50.4034 43.7963 46.7387 41.2801 43.686 37.9568C40.1878 34.148 38.1618 29.6502 37.4321 24.5361C36.706 19.4565 37.1007 14.3478 37.0229 9.25191C37.0066 8.2429 37.5661 7.51024 38.4859 7.11209C43.8417 4.79774 49.2029 2.4943 54.5642 0.189036C55.245 -0.103667 55.9113 -0.0382179 56.5884 0.254485C61.8573 2.53066 67.1262 4.80319 72.406 7.05573C73.1864 7.38843 73.639 7.98111 73.9993 8.69378C74.0012 13.0952 74.0012 17.4967 74.0012 21.8981ZM53.3673 23.918C52.4005 22.4653 51.5024 21.0982 50.5862 19.7438C49.938 18.7857 49.0092 18.3911 48.0586 18.6329C46.4363 19.0456 45.7591 20.7837 46.7024 22.2381C48.131 24.4415 49.5958 26.6214 51.0534 28.8066C52.2285 30.5683 53.9323 30.6374 55.2504 28.9848C56.7532 27.0995 58.256 25.2142 59.7588 23.3289C61.2309 21.48 62.7192 19.6456 64.1677 17.7785C65.3301 16.2804 64.6203 14.2879 62.826 13.9697C61.7885 13.7861 61.0353 14.2788 60.407 15.0751C58.0876 18.0039 55.7537 20.9218 53.3673 23.918Z" fill="#D3D3D3" />
                          <path d="M33.3519 27.8468C33.0603 27.8468 32.8232 27.8468 32.5878 27.8468C21.9721 27.8468 11.3546 27.8413 0.738987 27.8613C0.137862 27.8632 -0.00698778 27.7214 0.000254694 27.1196C0.0364671 24.4125 0.012929 21.7055 0.0165503 18.9966C0.0201715 15.8314 1.93762 13.9189 5.10982 13.9189C13.9692 13.9171 22.8267 13.9225 31.6861 13.9062C32.2257 13.9043 32.4067 14.0061 32.4013 14.597C32.3741 17.6168 32.3144 20.6401 32.6656 23.6508C32.8286 25.0398 33.0712 26.416 33.3519 27.8468Z" fill="#D3D3D3" />
                        </g>
                        <defs>
                          <clipPath id="clip0_182_3452">
                            <rect width="74" height="65" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </Link>
                    <p>Online ödə</p>
                  </div>
                  {courierPrice === null && <div className='payItem' onClick={() => paymentOrder('cash')}>
                    <button style={{ background: "transparent" }}  >{PashCashIcon}</button>
                    <p>Nağd ödə</p>
                  </div>}
                  {/* <div className='payItem'>
                  <Link>{PayBalanceIcon}</Link>
                  <p>Balans ilə ödə</p>
                </div>
                <div className='payItem'>
                  <Link>{PayInstallments}</Link>
                  <p>Hissə Hissə ödə</p>
                </div> */}
                </div>
              ) : <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "22vh" }}>
                <h2> Sifariş edilir <i className="fas fa-circle-notch fa-spin"></i></h2>
              </div>
            }


            {/* <div className='modalBtn'>
            <button type='button' className='btn btn__primary'>Sec</button>
          </div> */}



          </div>



        </Modal>


        <ToastContainer position="bottom-right" style={{ zIndex: 999999999 }} />


      </div>
    </section>
    </>
  )
}

export default Cartlist