import React from 'react'
import Menu from '../../components/ui/menu'
import Soon from '../../components/soon'

const Announcement = () => {
  return (
    <>
      <Menu/>
      <Soon/> 
    </>
  )
}

export default Announcement