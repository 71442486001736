import React, { useDeferredValue, useMemo, useState } from "react";
import { FiSearch } from "react-icons/fi";
import Breadcrumb from "../../components/ui/breadcrumb/breadcrumb";
import classes from "./dealers.module.css";
import DealerCard from "./dealer-card";
import Menu from "../../components/ui/menu";
import { useShops } from "../../hooks/query/shop";
import Meta from "../../components/ui/meta";
import { Link } from "react-router-dom";
import { SlArrowLeft } from "react-icons/sl";


const Dealers = () => {
    console.log("diler render")
    const { data } = useShops(1);
    const [name, setName] = useState("");
   
    const deferredName = useDeferredValue(name);

    const handleOnChange = (event) => {
        setName(event.target.value);
    };

    const list = useMemo(() => {
        if (deferredName) {
            return data?.data?.filter((shop) => shop.name.toLowerCase().includes(deferredName.toLowerCase()));
        }

        return data?.data;
    }, [deferredName, data?.data]);

    
    const page = {
        pages: [
            {
                link: "/dealers",
                content: "Rəsmi Diler",
            },
        ],
    };

    const title= 'Brand - Rəsmi dilerlər'
   

    return (
        <>
            <Meta title={title}/>
            <Menu />
            <Breadcrumb page={page} none='dnone' />
            <section className={`container`}>
            <Link className={`${classes.lnone}`} to="/"><SlArrowLeft color='#181818' fontSize="1.6rem" /></Link>
             <h1 className= {`${classes.head}`} >
             Rəsmi dillerlər</h1>
                <div className="shop__search">
                    <form>
                        <input type="text" onChange={handleOnChange} placeholder="Mağaza axtar..." />
                        <button>
                            <FiSearch color="#D4252A" fontSize="2rem" />
                        </button>
                    </form>
                </div>
                <div className={classes.dealers__cards}>
                    {list?.map((shop, i) => (
                        <DealerCard key={i} shop={shop} />
                    ))}
                </div>
            </section>
        </>
    );
};

export default Dealers;
