import { useQuery } from "@tanstack/react-query";
import { getNumber } from "../../services/registerNumberService";

export const useNumber = (id) => {
 
     const { data, isError, error,isFetching } = useQuery({
         queryKey: [`number_${id}`],
         queryFn: () => getNumber(id),
         staleTime: 10 * (60 * 1000),
         cacheTime: 10 * (60 * 1000),
     });

     return { data, isError, error ,isFetching };
     
 };
 