import { useQuery } from "@tanstack/react-query";
import { getShop, getShops } from "../../services/shopService";

export const useAllShops = () => {
    const { data, isError, error, refetch } = useQuery({
        queryKey: [`shops`],
        queryFn: () => getShops(),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, refetch };
};

export const useShops = (type) => {
    const params = { shop_type: type, direction: "desc",order:"position"  };
    const { data, isError, error, refetch } = useQuery({
        queryKey: [`shops_${type}`],
        queryFn: () => getShops(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error, refetch };
};


export const useShop = (id) => {
   // const params = { shop_type: 2, slug:slug  };
    const { data, isError, error } = useQuery({
        queryKey: [`shops_${id}`],
        queryFn: () => getShop(id),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
};
