import React from "react";
import Breadcrumb from "../../../components/ui/breadcrumb/breadcrumb";
import Menu from "../../../components/ui/menu";
import Meta from "../../../components/ui/meta";
import styles from './footer_about.module.css'
import { SlArrowLeft  } from "react-icons/sl";
import { Link } from "react-router-dom";


const FooterAbout = () => {

    const page = {
        pages: [
            {
                link: "/",
                content: "Haqqımızda",
            },
           
        ],
    };


    const title= 'Brand - Haqqımızda'

  return (
    <>
      <Meta title={title} />
      <Menu />
      <Breadcrumb page={page} none='dnone' />
      <section className="container">
          <Link className={`${styles.lnone}`} to="/"><SlArrowLeft color='#181818' fontSize="2rem" /></Link>
          <h1 className= {`${styles.head}`} >
            Haqqımızda</h1>
          <div className={`${styles.contents}`}>
         <p>Brand ehtiyat hissələrinin satışını edən veb saytdır.
             Sayt Mağazalar, Rəsmi dilerlər, Qeydiyyat nişanı, Ağır texnika, Ustalar, Xariçdən sifariş, Servislər bölmələrindən ibarətdir.
             Kataloqumuzda 35 mindən çox məhsul mövcuddur. Bunlar Ağır texnika və Avtomobil üçün ehtiyat hissələridir. </p>
          <p>Saytımızdan alış-veriş etməklə siz kifayət qədər sərfəli qiymətlərlə, vaxt itirmədən, trafikdə qalmadan, onlayn sifariş və çatdırılma imkanı ilə avtomobiliniz üçün ehtiyat hissələri əldə edə bilərsiniz. Biz də öz növbəmizdə sizin kimi daimi müştəri və ya etibarlı biznes tərəfdaşı əldə etməyə ümid edirik.</p>
          <p>Alıcılar saytda olan etibarlı satıcıdan ən yaxşı, ən əlverişli və sərfəli təklifləri seçir və bir neçə kliklə təhlükəsiz şəkildə sifariş edə və ya yerində ala bilərlər.</p>
          <p>Satıcılar bir neçə kliklə bizim saytda öz dükanını yaradıb, eyni gündə ilk sifarişləri qəbul edə və yüzlərlə müştərini cəlb edə bilərlər.</p>
           <h3>
             Brand hansı problemləri həll edir?
           </h3>
          <ul className={`${styles.about__list}`}>  
            <li>Vaxta qənaət edirsiz</li>
            <li>Trafikdə qalmırsız</li>
            <li>Axtarış rahatlaşır</li>
            <li>Qiyməti bilirsiz</li>
          </ul>
            

           <h3>Brand sizə nə vəd edir?</h3>  
           <ul className={`${styles.about__list}`}>
            <li>Vaxt qənaəti</li>
            <li>Pul qənaəti</li>
            <li>Sürətli və güvənilir çatdırılma</li>
            <li>Onlayn sifariş imkanı</li>
            <li>Yerində almaq imkanı</li>
            <li>Keyfiyyətli məhsul</li>
            </ul> 

           <h3>Nəyə görə məhz Brand?</h3>  
           <ul className={`${styles.about__list}`}>
            <li>Mütəxəssislərimiz tərəfindən seçilmiş sadə və aydın interfeys.</li>
            <li>Hər gün minlərlə insan Brand-da məhsul axtarır.</li>
            <li>Peşəkar, təsdiqlənmiş ustalar</li>
            <li>Sayt vasitəsilə 100% güvənilir alış</li>
            <li>Keyfiyyətli çatdırılma xidməti</li>
            </ul> 
          </div>
      </section>
    </>
  );
};

export default FooterAbout;
