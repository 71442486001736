import React, { useEffect, useState } from 'react';
import classes from './banner.module.css';
import ban1mob from "../../assets/images/ipoteka.png"
import ban2 from '../../assets/images/ban2.png';
import ban2mob from '../../assets/images/mob.png';
import ban3 from '../../assets/images/azaclub.jpg';
import ban3mob from '../../assets/images/azaclubmob.png';
import ban4 from "../../assets/images/11q.png"
import ban4mob from "../../assets/images/22q.png"
import { Link } from 'react-router-dom';

const images = [
  {
    link: 'https://www.premiumbank.az',
    src: ban2,
    srcMobile: ban2mob,
  },
  {
    link: 'https://www.azalclub.az',
    src: ban3,
    srcMobile: ban3mob,
  },
  {
    link: 'https://www.premiumbank.az',
    src: ban1mob,
    srcMobile: ban1mob,
  },
  {
    link: 'https://mercedes-autostar.az',
    src: ban4,
    srcMobile: ban4mob,
  }
];

const BannerSlider = () => {
  const screenSize = window.innerWidth;
  const [imageIndex, setImageIndex] = useState(Math.floor(Math.random() * images.length));

  const currentImage = screenSize > 600 ? images[imageIndex].src : images[imageIndex].srcMobile;

  const changeImage = () => {
    let newIndex = Math.floor(Math.random() * images.length);
    if (newIndex === imageIndex) {
      newIndex = (newIndex + 1) % images.length;
    }
    setImageIndex(newIndex);
  };

  useEffect(() => {
    changeImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <section className={`${classes.banner} mobilNone`}>
      <Link to={images[imageIndex].link} target="_blank">
        <img src={currentImage} alt="" />
      </Link>
    </section>
  );
};

export default BannerSlider;
