import { useQuery } from "@tanstack/react-query";
import { getPositionCategory, getRepairer, getRepairers } from "../../services/repairerService";


export const useRepairers = (params) => {
    const { data, isLoading, error, refetch, isStale, isFetching } = useQuery({
        queryKey: ["repairers", params],
        queryFn: () => getRepairers(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
        keepPreviousData: true,
    });

    return { data: data,isLoading, error, refetch, isStale, isFetching };
};


export const useRepairer = (slug) => {

    const { data, isError, error } = useQuery({
        queryKey: [`repairer_${slug}`],
        queryFn: () => getRepairer(slug),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000),
    });

    return { data, isError, error };
};



export const usePositionCategory = () => {
    const params = { direction: "desc " }

    const { data, isError, error } = useQuery({
        queryKey: ["position-category"],
        queryFn: () => getPositionCategory(params),
        staleTime: 10 * (60 * 1000),
        cacheTime: 10 * (60 * 1000)
    });

    return { data, isError, error };
};