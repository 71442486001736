import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import classes from "./tabpanel.module.css";

const Tabpanel = (props) => {
    const { setCategoryId, data  ,brand,setBrandIndex,brandIndex}=props
    const [currentIndex, setCurrentIndex] = useState(0);

    const categoryClick = (id, index,slug) => {
        setCurrentIndex(index);
        setCategoryId(id);
    };

    const brands=["Populyar markalar","Bütün markalar"]
  
   const handleBrand=(index)=>{
    setBrandIndex(index)
   }

    return (
        <ul className={classes.tabs}>
            {brand && brands.map((brand,index)=>(
                 <li key={index}>
                 <Link
                     className={index === brandIndex ? "activeTab" : ""}
                     onClick={()=>handleBrand(index)}
                 >
                     {brand}
                 </Link>
             </li>
            ))}
            {data?.data?.map((category, index) => {
                return (
                    <li key={category.id}>
                        <Link
                            className={index === currentIndex ? "activeTab" : ""}
                            onClick={() => categoryClick(category.id, index, category.category_name)}
                        >
                            {category.category_name}
                        </Link>
                    </li>
                );
            })}
        </ul>
    );
};

export default memo(Tabpanel);


